import { HTMLTemplateResult, LitElement, TemplateResult, html } from "lit";
import { property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

export type AtlasElementProps = {
    "skeleton-loading": boolean;
};

/**
 * @prop {boolean} skeleton-loading - Indica se o componente está carregando
 */
export default class AtlasElement extends LitElement {
    @property({ type: Boolean, attribute: "skeleton-loading", reflect: true }) skeletonLoading: boolean;

    @Watch("skeletonLoading")
    async applySkeleton() {
        await this.updateComplete;

        const allElements = this.shadowRoot.querySelectorAll("*");

        allElements.forEach((element: HTMLElement) => {
            if (element.tagName === "SLOT") {
                this.setLoadingSlot(element as HTMLSlotElement);
            } else {
                element.toggleAttribute("skeleton-loading", this.skeletonLoading);
            }
        });

        emit(this, "atlas-element-change-skeleton");
    }

    setLoadingSlot(slot: HTMLSlotElement) {
        const elements = slot.assignedElements();

        elements.forEach((element: HTMLElement) => {
            element.toggleAttribute("skeleton-loading", this.skeletonLoading);
        });
    }

    renderSkeleton(): HTMLTemplateResult | TemplateResult {
        return html``;
    }

    renderElement(): HTMLTemplateResult | TemplateResult {
        return html``;
    }

    render() {
        return when(
            this.skeletonLoading,
            () => this.renderSkeleton(),
            () => this.renderElement()
        );
    }
}
