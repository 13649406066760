import { LitElement, html } from "lit";
import { customElement, queryAssignedElements } from "lit/decorators.js";

import styles from "./atlas-summary-container.scss";

/**
 * @tag atlas-summary-container
 */
@customElement("atlas-summary-container")
export default class AtlasSummaryContainer extends LitElement {
    static styles = styles;

    @queryAssignedElements({ selector: "atlas-summary-card" })
    private _slottedCards: HTMLElement[];

    connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("atlas-summary-card-change-visibility", this.expandLastCardIfNeeded);
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-summary-card-change-visibility", this.expandLastCardIfNeeded);
    }

    async expandLastCardIfNeeded() {
        await this.updateComplete;

        const expandableCards = this._slottedCards.filter(
            (card) => !card.hasAttribute("hidden") && !card.hasAttribute("always-expanded")
        );

        if (expandableCards.length % 2 === 0) {
            const directionAttribute = expandableCards.length === 2 ? "expanded-vertically" : "expanded";

            expandableCards[expandableCards.length - 1].toggleAttribute(directionAttribute, true);
        } else {
            expandableCards.forEach((card) => {
                card.removeAttribute("expanded-vertically");
                card.removeAttribute("expanded");
            });
        }
    }

    render() {
        return html`
            <div class="summary-container">
                <slot @slotchange=${this.expandLastCardIfNeeded}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-summary-container": AtlasSummaryContainer;
    }
}
