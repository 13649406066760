import FormElement from "@/components/form/form-element";
import { DropzoneFile } from "@/internals/basic-types";

import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";

export const INPUT_ELEMENTS = [
    "atlas-datepicker",
    "atlas-float-input",
    "atlas-input",
    "atlas-integer-input",
    "atlas-masked-input",
    "atlas-money",
    "atlas-password-input",
    "atlas-percentage",
    "atlas-postal-code",
    "atlas-textarea"
];

export const CHECK_ELEMENTS = ["atlas-checkbox", "atlas-radio", "atlas-selection-card"];

export const SELECT_ELEMENTS = ["atlas-select", "atlas-toggle"];

const FORM_ELEMENTS = [
    "atlas-checkbox",
    "atlas-datepicker",
    "atlas-dropzone",
    "atlas-float-input",
    "atlas-input",
    "atlas-integer-input",
    "atlas-masked-input",
    "atlas-money",
    "atlas-password-input",
    "atlas-percentage",
    "atlas-postal-code",
    "atlas-radio",
    "atlas-select",
    "atlas-selection-card",
    "atlas-switch",
    "atlas-textarea",
    "atlas-toggle"
];

const isCheckboxElement = (element: FormElement): boolean => {
    const isCheckbox = element.tagName === "ATLAS-CHECKBOX";
    const isCardWithCheckbox =
        element.tagName === "ATLAS-SELECTION-CARD" && element.getAttribute("type") === "checkbox";

    return isCheckbox || isCardWithCheckbox;
};

const isRadioElement = (element: FormElement): boolean => {
    const isRadio = element.tagName === "ATLAS-RADIO";
    const isCardWithRadio =
        element.tagName === "ATLAS-SELECTION-CARD" && element.getAttribute("type") === "radio";

    return isRadio || isCardWithRadio;
};

const appendValuesToFormData = (formData: FormData, element: FormElement, elementValue: any) => {
    let value = elementValue;

    if (formData.has(element.name) && value === "") {
        return;
    }

    const isCheckbox = isCheckboxElement(element);
    const isRadio = isRadioElement(element);
    const isDropzone = element.tagName === "ATLAS-DROPZONE";

    if ((isRadio || isCheckbox) && value === "") {
        if (element.value !== "") return;

        value = (element as AtlasCheckbox).checked;
    }

    if (isDropzone) {
        const uploadedFiles = value as Array<DropzoneFile>;

        uploadedFiles.forEach((file) => {
            formData.append(`${element.name}[tempFileId]`, `${file.tempFileId}`);
            formData.append(`${element.name}[tempFileName]`, `${file.tempFileName}`);
        });

        return;
    }

    formData.append(element.name, value);
};

const appendValuesToObject = (object: { [key: string]: any }, element: FormElement, elementValue: any) => {
    let value = elementValue;
    const isCheckbox = isCheckboxElement(element);
    const isRadio = isRadioElement(element);
    const key = element.name;

    if ((isRadio || isCheckbox) && value === "") {
        if (element.value !== "") return;

        value = (element as AtlasCheckbox).checked;
    }

    if (!object[key]) {
        Object.assign(object, { [key]: value });
        return;
    }

    if (!Array.isArray(object[key])) {
        Object.assign(object, {
            [key]: [object[key]]
        });
    }

    Object.assign(object, {
        [key]: [...object[key], value]
    });
};

export const getAllFormElements = (container?: string | HTMLElement): Array<FormElement> => {
    let containerElement;

    if (container instanceof HTMLElement) {
        containerElement = container;
    } else if (container) {
        containerElement = document.querySelector(container);
    } else {
        containerElement = document.body;
    }

    const elements = containerElement.querySelectorAll(FORM_ELEMENTS.toString());

    return [].concat(...elements);
};

export const getFormValues = (container?: string | HTMLElement, asFormData?: boolean): FormData | object => {
    const formData = asFormData ? new FormData() : {};

    getAllFormElements(container).forEach((element) => {
        const value = element.getElementValue?.() || "";

        if (asFormData) {
            appendValuesToFormData(formData as FormData, element, value);
        } else {
            appendValuesToObject(formData, element, value);
        }
    });

    return formData;
};

export const checkFormValidity = (container?: string | HTMLElement): boolean => {
    const formElements = getAllFormElements(container);

    return formElements.every((element) => (element.checkValidity ? element.checkValidity() : false));
};

export const reportFormValidity = (container?: string | HTMLElement): boolean => {
    const formElements = getAllFormElements(container);
    let hasFocusOnFirstInvalidElement = false;
    let hasInvalidElement = false;

    formElements.forEach((element) => {
        if (!element.reportValidity || !element.reportValidity()) {
            hasInvalidElement = true;

            if (!hasFocusOnFirstInvalidElement) {
                hasFocusOnFirstInvalidElement = true;
                element.focus();
            }
        }
    });

    return !hasInvalidElement;
};
