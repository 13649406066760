import FormElement from "@/components/form/form-element";
import FieldValidator from "./field-validator";

import { stringToNumber } from "@/helpers/formatters";

export default class RangeValidator implements FieldValidator {
    name = "range";

    errorType: "none" | "min" | "max" = "none";

    maxValueErrorMessage: string;

    minValueErrorMessage: string;

    constructor(maxValueErrorMessage?: string, minValueErrorMessage?: string) {
        this.maxValueErrorMessage = maxValueErrorMessage || "O valor inserido excede o valor máximo";
        this.minValueErrorMessage = minValueErrorMessage || "O valor inserido é menor que o valor mínimo";
    }

    getInvalidMessage(): string {
        return this.errorType === "min" ? this.minValueErrorMessage : this.maxValueErrorMessage;
    }

    validate(element: FormElement): boolean {
        this.errorType = "none";

        const minValue = element.getAttribute("min-value");
        const maxValue = element.getAttribute("max-value");
        const value = stringToNumber(element.getElementValue());

        if (minValue && value < stringToNumber(minValue)) {
            this.errorType = "min";
        }

        if (maxValue && value > stringToNumber(maxValue)) {
            this.errorType = "max";
        }

        return this.errorType === "none";
    }
}
