import { html, TemplateResult } from "lit";
import { property } from "lit/decorators.js";

import { ClassInfo } from "lit/directives/class-map";
import { when } from "lit/directives/when.js";

import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";
import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";
import DeviceController from "@/controllers/device-controller";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import buttonStyles from "./atlas-button.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type ButtonBaseProps = WithTooltipProps &
    AtlasElementProps & {
        "type": "filled" | "outlined" | "ghost";
        "size": "sm" | "md" | "lg";
        "theme": Theme;
        "description": string;
        "disabled": boolean;
        "block": boolean;
        "pill": boolean;
        "icon": string;
        "href": string;
        "show-badge": boolean;
        "badge-number": number;
        "is-external-link": string;
        "atlas-button-click": string;
    };

/**
 * @dependency atlas-icon
 * @dependency atlas-tooltip
 *
 * @prop {"filled" | "outlined" | "ghost"} type - O tipo do botão
 * @prop {"sm" | "md" | "lg"} size - O tamanho do botão
 * @prop {Theme} theme - O estilo do botão
 * @prop {string} description - O texto do botão
 * @prop {boolean} disabled - Booleano que informa se o botão está desabilitado
 * @prop {boolean} block - Booleano que mostra se o botão é exibido como um bloco, ocupando 100% da largura disponível
 * @prop {boolean} pill - Booleano que informa se o botão possui bordas mais arredondadas
 * @prop {string} icon - O nome do ícone que será visível ao lado esquerdo do conteúdo do botão
 * @prop {string} href - Link da página que o usuário vai ser levado ao clicar no botão
 * @prop {string} is-external-link - Indica se a página que o botão vai redirecionar é uma página externa
 * @prop {string} tooltip - O texto do tooltip
 * @prop {number} badge-number- Indica o número que será colocado na badge
 * @prop {boolean} show-badge - Booleano que define se a badge deve aparecer
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 *
 * @event {CustomEvent} atlas-button-click - Evento disparado quando é feito o clique no botão
 *
 */
export class AtlasButtonBase extends WithTooltipMixin(AtlasElement) {
    static styles = buttonStyles;

    @property({ type: String }) type: "filled" | "outlined" | "ghost" = "filled";

    @property({ type: String }) size: "sm" | "md" | "lg" = "md";

    @property({ type: String }) theme: Theme = "primary";

    @property({ type: String }) description: string;

    @property({ type: Boolean }) disabled: boolean;

    @property({ type: Boolean }) block: boolean;

    @property({ type: Boolean }) pill: boolean;

    @property({ type: Boolean }) loading: boolean;

    @property({ type: String }) icon: string;

    @property({ type: String }) href: string;

    @property({ type: Boolean, attribute: "is-external-link" }) isExternalLink: boolean;

    @property({ type: Boolean, attribute: "show-badge" }) showBadge = false;

    @property({ type: Number, attribute: "badge-number" }) badgeNumber: number;

    private _deviceController = new DeviceController(this);

    /**
     * Enable the button
     * @public
     */
    enable() {
        this.disabled = false;
    }

    /**
     * Disable the button
     * @public
     */
    disable() {
        this.disabled = true;
    }

    handleButtonClick(event: PointerEvent) {
        event.stopPropagation();

        emit(this, "atlas-button-click");
    }

    isDisabled() {
        return this.disabled || this.loading;
    }

    getButtonSize() {
        return this._deviceController.isMobile ? "lg" : this.size;
    }

    getButtonClasses(): ClassInfo {
        return {
            "btn": true,
            "btn-block": this.block,
            "disabled": this.disabled,
            [`btn-${this.getButtonSize()}`]: true,
            [`btn-${this.theme}`]: this.type === "filled",
            [`btn-outline-${this.theme}`]: this.type === "outlined",
            [`btn-ghost-${this.theme}`]: this.type === "ghost"
        };
    }

    getCurrentIcon() {
        return this.loading ? "loader" : this.icon;
    }

    getButtonIcon(icon: string): TemplateResult {
        return when(!!icon, () => html`<atlas-icon size="auto" name="${icon}"></atlas-icon>`);
    }

    getButtonText(): TemplateResult {
        return when(!!this.description, () => html`<span>${this.description}</span>`);
    }

    renderBadge() {
        return when(
            this.showBadge,
            () => html`<atlas-badge theme="${this.theme}" text=${this.badgeNumber} is-counter></atlas-badge>`
        );
    }

    renderSkeleton() {
        return html`<div class="skeleton btn btn-${this.size}"></div>`;
    }
}
