import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";

import styles from "./atlas-offcanvas.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";

export type OffcanvasProps = {
    open: boolean;
    header: string;
};

/**
 * @dependency atlas-icon
 * @dependency atlas-heading
 *
 * @prop {boolean} open - Define se Offcanvas está aberto
 * @prop {string} header - Define o título do cabeçalho do offcanvas
 *
 * @tag atlas-offcanvas
 */
@customElement("atlas-offcanvas")
export default class AtlasOffcanvas extends LitElement {
    static styles = styles;

    @property({ type: Boolean, reflect: true }) open: boolean;

    @property({ type: String }) header: string;

    constructor() {
        super();

        this.onClickOffCanvas = this.onClickOffCanvas.bind(this);
    }

    close() {
        this.open = false;
        emit(this, "atlas-offcanvas-close");
    }

    onClickOffCanvas(event: PointerEvent) {
        if (!this.open) {
            return;
        }

        const composedPath = event.composedPath();
        const isOffCanvasTarget = composedPath.includes(this.shadowRoot.querySelector(".atlas-offcanvas"));

        if (!isOffCanvasTarget) {
            this.close();
        }
    }

    render() {
        const offCanvasBackdropClass = {
            "atlas-offcanvas-backdrop": true,
            "show": this.open
        };

        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <div class="${classMap(offCanvasBackdropClass)}" @click=${this.onClickOffCanvas}>
                <div class="atlas-offcanvas">
                    <div class="atlas-offcanvas-header">
                        <atlas-heading type="h5">${this.header}</atlas-heading>

                        <button class="close-button" @click=${this.close}>
                            <atlas-icon name="x" size="3x" theme="secondary"></atlas-icon>
                        </button>
                    </div>
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-offcanvas": AtlasOffcanvas;
    }
}
