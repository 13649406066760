import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import { Theme } from "@/internals/theme";
import styles from "./atlas-icon-button.scss";

import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-tooltip/atlas-tooltip";

const ComposedClass = WithPopoverMixin(WithTooltipMixin(AtlasElement));

export type IconProps = AtlasElementProps &
    WithTooltipProps &
    WithPopoverProps & {
        "icon": string;
        "theme": Theme;
        "size": "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x";
        "disabled": boolean;
        "hoverable": boolean;
        "active": boolean;
        "dot": boolean;
        "badge-number": number;
    };

/**
 * Botões de ícone são usados para executar ações quando clicados e possuem algumas interações diferentes de um botão ou um ícone
 *
 * @dependency atlas-icon
 *
 * @prop {string} icon - O nome do ícone
 * @prop {Theme} theme - O tema do botão
 * @prop {IconSize} size - O tamanho do botão
 * @prop {boolean} disabled - Indica se o botão está desabilitado
 * @prop {boolean} hoverable -  Indica se o componente tem o comportamento de hover, atribuindo um padding adicional e efeitos ao passar o mouse sobre ele
 * @prop {boolean} active - Indica se o componente está ativo, deve ser utilizado em conjunto com a propriedade `hoverable`
 * @prop {boolean} dot - Indica se irá aparecer uma bolinha de notificação acima do ícone
 * @prop {number} badge-number - Indicador que aparece acima do botão, contendo o número informado
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 *
 * @tag atlas-icon-button
 */
@customElement("atlas-icon-button")
export default class AtlasIconButton extends ComposedClass {
    static styles = styles;

    @property({ type: String }) icon: string;

    @property({ type: String }) theme: Theme;

    @property({ type: String }) size: "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" = "3x";

    @property({ type: Boolean }) disabled = false;

    @property({ type: Boolean }) hoverable = false;

    @property({ type: Boolean }) active = false;

    @property({ type: Boolean, attribute: "show-badge" }) showBadge = false;

    @property({ type: Number, attribute: "badge-number" }) badgeNumber: number;

    private deviceController = new DeviceController(this);

    renderBadge() {
        return when(this.showBadge, () => html`<atlas-badge text=${this.badgeNumber} is-counter></atlas-badge>`);
    }

    render() {
        const buttonClass = {
            "icon-button": true,
            "disabled": this.disabled,
            "hoverable": this.hoverable && !this.deviceController.isMobile,
            "with-popover": this.hasPopover(),
            "active": this.active
        };

        return html`
            <div
                class="btn-wrapper"
                tabindex=${this.disabled ? "0" : "-1"}
                data-atlas-tooltip="btn-icon-tooltip"
                data-atlas-popover="btn-icon-popover"
            >
                <button class=${classMap(buttonClass)} ?disabled=${this.disabled}>
                    <atlas-icon
                        name=${this.icon}
                        theme=${this.theme}
                        size=${this.size}
                        ?disabled=${this.disabled}
                    ></atlas-icon>
                    ${this.renderBadge()}
                </button>
            </div>
            ${this.renderTooltip("btn-icon-tooltip")}${this.renderPopover("btn-icon-popover")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-icon-button": AtlasIconButton;
    }
}
