import { LitElement } from "lit";
import { property, state } from "lit/decorators.js";

import type { Instance as PopperInstance } from "@popperjs/core";
import { createPopper } from "@popperjs/core";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import { OverlayPlacement, OverlayTrigger } from "./overlay";
import type AtlasInput from "@/components/form/atlas-input/atlas-input";

export type OverlayElementProps = {
    id: string;
    open: boolean;
    disabled: boolean;
    placement: OverlayPlacement;
    trigger: OverlayTrigger;
};

/**
 * O OverlayElement é a classe base para elementos do tipo overlay, ex: Tooltip, Dropdown, Popover. Ele define algumas propriedades padrão e alguns comportamentos presentes em todos os overlays
 *
 * @prop {boolean} open - Indica se o {overlay} está aberto
 * @prop {boolean} disabled - Indica se o {overlay} está desabilitado
 * @prop {OverlayPlacement} placement - A posição do {overlay} em relação ao elemento que o acionou
 * @prop {OverlayTrigger} trigger - O gatilho que irá acionar o {overlay}
 *
 */
export default abstract class OverlayElement extends LitElement {
    @property({ type: Boolean, reflect: true }) open = false;

    @property({ type: Boolean }) disabled;

    @property({ type: String }) placement: OverlayPlacement;

    @property({ type: String }) trigger: OverlayTrigger;

    @state() protected _triggerElement?: HTMLElement;

    private _overlayType?: string;

    private _overlayStrategy?: "fixed" | "absolute";

    protected _popperInstance?: PopperInstance;

    constructor(
        type: string,
        overlayStrategy: "fixed" | "absolute",
        defaultTrigger?: OverlayTrigger,
        defaultPlacement?: OverlayPlacement
    ) {
        super();

        this.open = false;
        this.disabled = false;
        this.placement = defaultPlacement || "bottom";
        this.trigger = defaultTrigger || "hover focus";
        this._overlayType = type;
        this._overlayStrategy = overlayStrategy;

        this.syncTriggerElement = this.syncTriggerElement.bind(this);
        this.updatePosition = this.updatePosition.bind(this);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleTriggerFocus = this.handleTriggerFocus.bind(this);
        this.handleTriggerBlur = this.handleTriggerBlur.bind(this);
        this.handleTriggerClick = this.handleTriggerClick.bind(this);
        this.handleTriggerMouseEnter = this.handleTriggerMouseEnter.bind(this);
        this.handleTriggerMouseLeave = this.handleTriggerMouseLeave.bind(this);
    }

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.syncTriggerElement();
        });
    }

    disconnectedCallback() {
        this.clearTriggerEvents();
        this._popperInstance?.destroy();
    }

    abstract getOverlayElement(): HTMLElement;

    bindTriggerEvents() {
        this._triggerElement?.addEventListener("focus", this.handleTriggerFocus, true);
        this._triggerElement?.addEventListener("blur", this.handleTriggerBlur, true);
        this._triggerElement?.addEventListener("click", this.handleTriggerClick);
        this._triggerElement?.addEventListener("mouseenter", this.handleTriggerMouseEnter);
        this._triggerElement?.addEventListener("mouseleave", this.handleTriggerMouseLeave);
    }

    clearTriggerEvents() {
        this._triggerElement?.removeEventListener("focus", this.handleTriggerFocus, true);
        this._triggerElement?.removeEventListener("blur", this.handleTriggerBlur, true);
        this._triggerElement?.removeEventListener("click", this.handleTriggerClick);
        this._triggerElement?.removeEventListener("mouseenter", this.handleTriggerMouseEnter);
        this._triggerElement?.removeEventListener("mouseleave", this.handleTriggerMouseLeave);
    }

    findTriggerElement(parentNode: Node | HTMLElement): HTMLElement {
        let element: HTMLElement = null;

        if (parentNode instanceof ShadowRoot) {
            element = parentNode.querySelector(`[data-atlas-${this._overlayType}=${this.id}]`);

            if (!element) {
                element = this.findTriggerElement(parentNode.host.parentNode);
            }
        } else if (parentNode instanceof Document) {
            element = parentNode.querySelector(`[data-atlas-${this._overlayType}=${this.id}]`);
        } else {
            element = this.findTriggerElement(parentNode.getRootNode());
        }

        if (element && element.tagName === "ATLAS-INPUT") {
            element = (element as AtlasInput)._input;
        }

        return element;
    }

    syncTriggerElement() {
        this._popperInstance?.destroy();
        this._popperInstance = null;
        this.clearTriggerEvents();

        this._triggerElement = this.findTriggerElement(this.parentNode);
        this.bindTriggerEvents();
        this.onSyncTriggerElement();
    }

    onSyncTriggerElement(): void {}

    getPopperModifiers(): Array<object> {
        return [
            { name: "flip", options: { boundary: "viewport" } },
            { name: "offset", options: { offset: [0, 8] } },
            { name: "preventOverflow", options: { boundary: "clippingParents" } }
        ];
    }

    createPopperInstance() {
        if (this._popperInstance) {
            this.updatePosition();
            return;
        }

        this._popperInstance = createPopper(this._triggerElement, this.getOverlayElement(), {
            placement: this.placement,
            strategy: this._overlayStrategy,
            modifiers: this.getPopperModifiers()
        });
    }

    updatePosition() {
        this._popperInstance?.update();
    }

    @Watch("placement")
    syncOptions() {
        this._popperInstance?.setOptions({
            placement: this.placement,
            strategy: this._overlayStrategy,
            modifiers: this.getPopperModifiers()
        });
    }

    hasTrigger(triggerType: string) {
        return this.trigger.split(" ").includes(triggerType);
    }

    show() {
        if (this.disabled) {
            return;
        }

        this.open = true;
    }

    hide() {
        this.open = false;
    }

    toggle() {
        if (this.open) {
            this.hide();
        } else {
            this.show();
        }
    }

    handleTriggerFocus() {
        if (this.hasTrigger("focus")) {
            this.show();
        }
    }

    handleTriggerBlur() {
        if (this.hasTrigger("focus")) {
            this.hide();
        }
    }

    handleTriggerClick() {
        if (this.hasTrigger("click")) {
            this.toggle();
        }
    }

    handleTriggerMouseEnter() {
        if (this.hasTrigger("hover")) {
            this.show();
        }
    }

    handleTriggerMouseLeave() {
        if (this.hasTrigger("hover")) {
            this.hide();
        }
    }

    abstract onOpenOverlay(): void;

    abstract onCloseOverlay(): void;

    @Watch("open", true)
    onOpenChange() {
        if (this.open) {
            this.createPopperInstance();

            window.addEventListener("scroll", this.updatePosition);
            window.addEventListener("resize", this.updatePosition);

            this.onOpenOverlay();
            emit(this, `atlas-${this._overlayType}-opened`);
        } else {
            window.removeEventListener("scroll", this.updatePosition);
            window.removeEventListener("resize", this.updatePosition);

            this.onCloseOverlay();
            emit(this, `atlas-${this._overlayType}-closed`);
        }
    }
}
