import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import { OverlayPlacement, OverlayTrigger } from "@/components/display/overlay";
import { Theme, ThemeVariation } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import iconStyles from "./atlas-icon.scss";

import "@/components/display/atlas-tooltip/atlas-tooltip";

export type IconProps = AtlasElementProps & {
    "name": string;
    "theme": Theme;
    "theme-variation": ThemeVariation;
    "size": "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x";
    "disabled": boolean;
    "tooltip": string;
    "tooltip-placement": OverlayPlacement;
    "tooltip-trigger": OverlayTrigger;
};

/**
 * Ícones servem para reforço visual de forma rápida e direta, os ícones também servem como símbolos que representam algo como uma aplicação ou função do sistema.
 *
 * @dependency atlas-tooltip
 *
 * @prop {string} name - O nome do ícone
 * @prop {Theme} theme - O tema do ícone
 * @prop {ThemeVariation} theme-variation - A variação da tonalidade da cor do tema
 * @prop {"auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x"} size - O tamanho do ícone
 * @prop {boolean} disabled - Indica se o ícone aparece com os estilos de desabilitado
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 *
 * @tag atlas-icon
 */
@customElement("atlas-icon")
export class AtlasIcon extends AtlasElement {
    static styles = iconStyles;

    @property({ type: String }) name: string;

    @property({ type: String }) theme: Theme;

    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation;

    @property({ type: String }) size: "auto" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" = "3x";

    @property({ type: Boolean }) disabled = false;

    @property({ type: String }) tooltip: string;

    @property({ type: String, attribute: "tooltip-placement" }) tooltipPlacement: OverlayPlacement = "bottom";

    @property({ type: String, attribute: "tooltip-trigger" }) tooltipTrigger: OverlayTrigger = "hover focus";

    getThemeClass() {
        if (this.theme) {
            if (this.themeVariation) {
                return `color-${this.theme}-${this.themeVariation}`;
            }

            return `color-${this.theme}`;
        }

        return "";
    }

    getTooltip() {
        return when(
            !!this.tooltip,
            () => html`
                <atlas-tooltip id="icon-tooltip" placement=${this.tooltipPlacement} trigger=${this.tooltipTrigger}>
                    ${this.tooltip}
                </atlas-tooltip>
            `
        );
    }

    renderElement() {
        const themeClass = this.getThemeClass();

        const iconClass = {
            "atlas-icon": true,
            [`ati-${this.name}`]: true,
            [`ati-${this.size}`]: true,
            [themeClass]: !!themeClass,
            "disabled": this.disabled
        };

        return html`
            <i
                class="${classMap(iconClass)}"
                data-atlas-tooltip=${ifDefined(this.tooltip ? "icon-tooltip" : undefined)}
            ></i>
            ${this.getTooltip()}
        `;
    }

    renderSkeleton() {
        return html` <div class="skeleton atlas-icon ati-${this.size}"></div> `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-icon": AtlasIcon;
    }
}
