import { html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import FormControl, { FormControlProps } from "@/components/form/form-control";
import EmojiValidator from "@/internals/validators/emoji-validator";
import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { WithTooltipMixin, WithTooltipProps } from "@/internals/mixins/with-tooltip-mixin";

import { InputType, InputSize } from "./types";
import inputStyles from "./atlas-input.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-button/atlas-button";

export type InputProps = FormControlProps &
    WithTooltipProps & {
        "type": InputType;
        "size": InputSize;
        "placeholder": string;
        "icon": string;
        "has-icon-event": boolean;
        "action-button-icon": string;
        "prefix": string;
        "maxlength": number;
        "readonly": boolean;
        "loading": boolean;
        "enable-emoji": boolean;
    };

/**
 * @prop {InputType} type - O tipo de input
 * @prop {InputSize} size - O tamanho do input
 * @prop {string} placeholder - A mensagem que aparecerá quando o input está vazio
 * @prop {string} label - A label que ficará acima do input determinando o nome do mesmo
 * @prop {string} helper-text - O texto auxiliar que pode ser utilizado no input
 * @prop {string} prefix - Prefixo que aparecerá no input
 * @prop {string} icon - Ícone exibido ao lado direito do input
 * @prop {boolean} has-icon-event - Indica se o ícone exibido no input terá alguma ação
 * @prop {string} action-button-icon - Ícone em forma de botão que irá aparecer ao lado do input que quando clicado emite um evento
 * @prop {number} maxlength - O tamanho máximo de caracteres do input
 * @prop {boolean} readonly - Indica se o input é apenas para leitura
 * @prop {boolean} loading - Indica se o input é está em estado de loading
 * @prop {boolean} enable-emoji - Indica se o input pode conter emoji
 * @prop {string} id - O id do input, usado para fazer associações de ARIA
 * @prop {string} name - O nome do input
 * @prop {string} value - O valor do inicial do input
 * @prop {boolean} disabled - Indica se o input está desabilitado
 * @prop {boolean} required - Indica se o input é obrigatório
 * @prop {string} tooltip - O texto do tooltip
 * @prop {OverlayPlacement} tooltip-placement - A posição em relação ao elemento que o tooltip será exibido
 * @prop {OverlayTrigger} tooltip-trigger - O gatilho que irá acionar o tooltip
 *
 * @event {CustomEvent} atlas-input-focus - Evento lançado quando é dado foco no input
 * @event {CustomEvent} atlas-input-blur - Evento lançado quando é retirado o foco do input
 * @event {CustomEvent} atlas-input-change - Evento lançado quando é o valor do input é alterado
 * @event {CustomEvent} atlas-input-icon-click - Evento lançado quando é clicado sobre o ícone do input
 * @event {CustomEvent} atlas-input-action-button-click - Evento lançado quando é clicado sobre o botão de ação do input
 *
 * @tag atlas-input
 */
@customElement("atlas-input")
export default class AtlasInput extends WithTooltipMixin(FormControl) {
    static styles = inputStyles;

    @property({ type: String }) type: InputType = "text";

    @property({ type: String }) size: InputSize = "md";

    @property({ type: String }) placeholder: string;

    @property({ type: String, attribute: "prefix" }) inputGroupPrefix: string;

    @property({ type: String }) icon: string;

    @property({ type: Boolean, attribute: "has-icon-event" }) hasIconEvent: boolean;

    @property({ type: String, attribute: "action-button-icon" }) actionButtonIcon: string;

    @property({ type: Number }) maxlength: number;

    @property({ type: Boolean, reflect: true }) readonly = false;

    @property({ type: Boolean }) loading = false;

    @property({ type: Boolean, attribute: "enable-emoji" }) enableEmoji = false;

    @query("input") _input: HTMLInputElement;

    protected _deviceController = new DeviceController(this);

    connectedCallback(): void {
        if (!this.enableEmoji && this.tagName === "ATLAS-INPUT") {
            this.addValidator(new EmojiValidator());
        }

        super.connectedCallback?.();
    }

    focus() {
        this._input.focus();

        setTimeout(() => {
            this._input.selectionStart = this.value.length;
            this._input.selectionEnd = this.value.length;
        }, 0);
    }

    blur() {
        this._input.blur();
    }

    handleFocus() {
        emit(this, "atlas-input-focus");
    }

    handleBlur() {
        this.reportValidity();
        emit(this, "atlas-input-blur");
    }

    handleInput() {
        this.value = this._input.value;
    }

    @Watch("value", true)
    onChangeValue() {
        emit(this, "atlas-input-change", { detail: this.value });
    }

    onInputIconClick() {
        emit(this, "atlas-input-icon-click");
    }

    onActionButtonClick() {
        emit(this, "atlas-input-action-button-click");
    }

    renderInputPrefix() {
        return when(
            !!this.inputGroupPrefix,
            () => html`<span class="input-group-text">${this.inputGroupPrefix}</span>`
        );
    }

    renderIcon() {
        return when(this.loading || !!this.icon, () => {
            const iconClass = {
                "input-icon": true,
                "has-click": !this.loading && this.hasIconEvent
            };

            return html`
                <atlas-icon
                    name=${this.loading ? "loader" : this.icon}
                    size="2x"
                    class=${classMap(iconClass)}
                    @click=${this.onInputIconClick}
                ></atlas-icon>
            `;
        });
    }

    renderInput() {
        const ariaLabel = this.disabled || this.readonly ? this.placeholder : "";
        const ariaDescribedBy = this.helperText ? `${this.id}-helper` : "";

        const inputClass = {
            "form-control": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg" || this._deviceController.isMobile,
            "is-invalid": this._showValidationState && !this._valid,
            "has-icon": this.loading || !!this.icon,
            "has-action-button": !!this.actionButtonIcon
        };

        return html`
            <input
                aria-describedby="${ariaDescribedBy}"
                aria-label="${ariaLabel}"
                id="${this.id}"
                name="${this.name}"
                class=${classMap(inputClass)}
                type=${this.type}
                inputmode=${ifDefined(this.inputMode)}
                placeholder="${this.placeholder}"
                maxlength=${ifDefined(this.maxlength || undefined)}
                .value=${live(this.value)}
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                @focus=${this.handleFocus}
                @blur=${this.handleBlur}
                @input=${this.handleInput}
                autocomplete="off"
            />
            ${this.renderIcon()} ${this.renderValidationMessage()}
        `;
    }

    renderActionButton() {
        return when(
            !!this.actionButtonIcon,
            () => html`
                <atlas-button
                    ?disabled=${this.disabled || this.loading}
                    icon=${this.actionButtonIcon}
                    size=${this._deviceController.isMobile ? "lg" : this.size}
                    theme="secondary"
                    type="outlined"
                    in-group-last
                    @atlas-button-click=${this.onActionButtonClick}
                ></atlas-button>
            `
        );
    }

    render() {
        return html`
            ${this.renderLabel()}
            <div class="input-wrapper">
                <div class="input-group" data-atlas-tooltip="input-tooltip">
                    ${this.renderInputPrefix()} ${this.renderInput()}
                </div>
                ${this.renderActionButton()}
            </div>
            ${this.renderHelperText()} ${this.renderTooltip("input-tooltip")}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-input": AtlasInput;
    }
}
