import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { WithPopoverMixin } from "@/internals/mixins/with-popover-mixin";
import { getAllFormElements } from "@/helpers/form";
import { emit } from "@/internals/events";
import FormElement from "@/components/form/form-element";

import styles from "./atlas-section.scss";

import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-heading/atlas-heading";

/**
 * @prop {string} header - Título da seção
 * @prop {string} description - Descrição da seção
 * @prop {boolean} collapsible - Indica se a seção é colapsável
 * @prop {boolean} optional - Indica se a seção é opcional
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 *
 *  @slot description - Slot onde pode ser incluido a descrição da section - o parâmetro description vai ser priorizado
 *
 * @tag atlas-section
 */
@customElement("atlas-section")
export default class AtlasSection extends WithPopoverMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: Boolean }) collapsible = false;

    @property({ type: Boolean, reflect: true }) optional = false;

    @property({ type: Boolean, attribute: "is-faq" }) isFaq = false;

    @property({ type: Boolean, attribute: "default-expanded" }) defaultExpanded = false;

    @state() private _collapsed = true;

    @state() private _hasSlottedDescription = false;

    connectedCallback() {
        super.connectedCallback?.();

        this.updateOptionalState = this.updateOptionalState.bind(this);
        this.addEventListener("atlas-element-required-change", this.updateOptionalState);

        if (this.defaultExpanded) {
            this._collapsed = false;
        }

        this.updateComplete.then(() => {
            this.updateOptionalState();
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-element-required-change", this.updateOptionalState);
    }

    open() {
        this._collapsed = false;
        emit(this, "atlas-section-expand");
    }

    close() {
        if (this.collapsible) {
            this._collapsed = true;
        }

        emit(this, "atlas-section-collapse");
    }

    toggle() {
        if (this.collapsible) {
            if (this._collapsed) {
                this.open();
            } else {
                this.close();
            }
        }
    }

    async updateOptionalState() {
        await this.updateComplete;

        const formElements = getAllFormElements(this);
        const allOptional =
            formElements.length > 0 ? formElements.every((element: FormElement) => !element.required) : false;

        this.optional = allOptional;

        formElements.forEach((element: FormElement) => {
            const optionalLabel: HTMLElement = element.shadowRoot.querySelector(".form-label-optional");

            if (optionalLabel) {
                optionalLabel.hidden = allOptional;
            }
        });
    }

    onDescriptionChange() {
        const descriptionSlot = this.shadowRoot.querySelector("slot[name=description]") as HTMLSlotElement;
        if (!descriptionSlot) return;

        const slottedText = descriptionSlot.assignedElements()?.[0];

        slottedText?.toggleAttribute("muted");
        this._hasSlottedDescription = !!slottedText;
    }

    renderHeader() {
        const headingType = this.isFaq ? "h6" : "h4";

        return when(
            this.collapsible,
            () => html`
                <button class="section-header" @click=${this.toggle}>
                    <atlas-heading
                        type="${headingType}"
                        popover-title=${this.popoverTitle}
                        popover-content=${this.popoverContent}
                    >
                        ${this.header}${this.optional ? " (Opcional)" : ""}
                    </atlas-heading>
                    <atlas-icon name="chevron-up" size="3x"></atlas-icon>
                </button>
            `,
            () => html`
                <div class="section-header">
                    <atlas-heading type="h4" popover-title=${this.popoverTitle} popover-content=${this.popoverContent}>
                        ${this.header}${this.optional ? " (Opcional)" : ""}
                    </atlas-heading>
                </div>
            `
        );
    }

    renderDescription() {
        if (this.description) {
            return html`<p class="section-description">${this.description}</p>`;
        }

        return html`
            <div class="section-description">
                <slot name="description" @slotchange=${this.onDescriptionChange}></slot>
            </div>
        `;
    }

    render() {
        const sectionClass = {
            "section": true,
            "collapsible": this.collapsible,
            "collapsed": this._collapsed,
            "is-faq": this.isFaq,
            "hide-description": !this._hasSlottedDescription && !this.description
        };

        return html`
            <div class=${classMap(sectionClass)}>
                ${this.renderHeader()}
                <div class="section-body">
                    <atlas-collapse ?show=${!this.collapsible || !this._collapsed}>
                        ${this.renderDescription()}
                        <slot></slot>
                    </atlas-collapse>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-section": AtlasSection;
    }
}
