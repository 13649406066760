import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { emit } from "@/internals/events";

import styles from "./atlas-toggle-item.scss";

/**
 * Componente de toggle-item, serve para delimitar quantas opções existem dentro de um toggle
 *
 * @prop {string} value - O valor que está atrelado ao input do componente
 * @prop {string} label - A label que será exibida na visualização do componente
 * @prop {string} helper-text - O texto informativo auxiliar que diz respeito ao item
 * @prop {boolean} disabled - Indica se o item está desabilitado
 *
 * @tag atlas-toggle-item
 */
@customElement("atlas-toggle-item")
export default class AtlasToggleItem extends LitElement {
    static styles = styles;

    @property({ type: String }) value = "";

    @property({ type: String }) label = "";

    @property({ type: String, attribute: "helper-text" }) helperText = "";

    @property({ type: Boolean, reflect: true }) disabled = false;

    @state() checked = false;

    check() {
        this.checked = true;
        emit(this, "atlas-toggle-item-check");
    }

    uncheck() {
        this.checked = false;
    }

    handleItemClick() {
        this.check();
    }

    render() {
        const btnClass = {
            "btn": true,
            "btn-outline-primary": true,
            "disabled": this.disabled
        };

        return html`
            <input
                id="toggle-item"
                class="btn-check"
                type="radio"
                .value="${this.value}"
                .checked=${this.checked}
                ?disabled=${this.disabled}
                @click=${this.handleItemClick}
            />
            <label class=${classMap(btnClass)} for="toggle-item">${this.label}</label>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toggle-item": AtlasToggleItem;
    }
}
