import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";

import styles from "./atlas-link.scss";
import "@/components/display/atlas-icon/atlas-icon";

/**
 * @dependency atlas-icon
 *
 * @prop {string} href - Link que será aberto ao clicar no item
 * @prop {string} icon - Ícone que irá aparecer ao lado esquerdo do texto
 * @prop {boolean} small - Indica se o texto do ícone é pequeno
 * @prop {boolean} external - Indica se o link que vai ser aberto ao clicar sobre o item é um link externo
 * @prop {boolean} disabled - Indica se o link vai aparecer como desabilitado
 *
 * @tag atlas-link
 */
@customElement("atlas-link")
export default class AtlasLink extends LitElement {
    static styles = styles;

    @property({ type: String }) href: string;

    @property({ type: String }) icon: string;

    @property({ type: Boolean }) small: boolean;

    @property({ type: Boolean }) external: boolean;

    @property({ type: Boolean }) disabled: boolean;

    render() {
        const linkClass = {
            link: true,
            small: this.small,
            disabled: this.disabled
        };

        return html`
            <a
                href="${this.href}"
                class=${classMap(linkClass)}
                rel="${ifDefined(this.external ? "noreferrer" : undefined)}"
                target="${ifDefined(this.external ? "_blank" : undefined)}"
            >
                ${when(!!this.icon, () => html`<atlas-icon name=${this.icon} size="auto"></atlas-icon>`)}
                <span>
                    <slot></slot>
                </span>
            </a>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-link": AtlasLink;
    }
}
