import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";
import { Theme } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-card.scss";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon-button/atlas-icon-button";

export type CardProps = WithPopoverProps &
    AtlasElementProps & {
        "image": string;
        "image-description": string;
        "header": string;
        "background": "white" | "light";
        "badge-text": string;
        "badge-theme": Theme;
    };

/**
 * @dependency atlas-badge
 * @dependency atlas-heading
 * @dependency atlas-icon-button
 *
 * @prop {string} image - Imagem que aparece no topo do card
 * @prop {string} image-description - Texto de descrição da imagem (Para acessibilidade)
 * @prop {string} header - Título do card
 * @prop {string} background - Cor de fundo do card
 * @prop {string} badge-text - Texto do badge
 * @prop {Theme} badge-theme - Tema do badge
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 *
 * @tag atlas-card
 */
@customElement("atlas-card")
export default class AtlasCard extends WithPopoverMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) image: string;

    @property({ type: String, attribute: "image-description" }) imageDescription: string;

    @property({ type: String }) header: string;

    @property({ type: String }) background: "white" | "light" = "white";

    @property({ type: String, attribute: "badge-text" }) badgeText: string;

    @property({ type: String, attribute: "badge-theme" }) badgeTheme: Theme = "primary";

    @state() private _showFooter = false;

    private _deviceController = new DeviceController(this, this.onChangeScreenType.bind(this));

    getSlottedActions() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;
        return actionsSlot.assignedElements();
    }

    onSlotActions() {
        this._showFooter = this.getSlottedActions().length > 0;
    }

    async onChangeScreenType() {
        await this.updateComplete;

        this.getSlottedActions()
            .filter((element) => element.tagName === "ATLAS-BUTTON")
            .forEach((element) => element.toggleAttribute("block", this._deviceController.isMobile));
    }

    renderSkeletonImage() {
        return when(
            this.skeletonLoading,
            () => html` <div class="skeleton"></div> `,
            () => html` <img src=${this.image} alt=${this.imageDescription} /> `
        );
    }

    renderHeroImage() {
        return when(!!this.image, () => html` <div class="hero-image">${this.renderSkeletonImage()}</div> `);
    }

    renderBadge() {
        return when(
            !!this.badgeText && !this.skeletonLoading,
            () => html`<atlas-badge theme="${this.badgeTheme}" text=${this.badgeText}></atlas-badge>`
        );
    }

    renderInfoIcon() {
        return when(
            this.hasPopover() && !this.badgeText,
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    renderFooter() {
        return html`
            <div class="card-footer">
                <slot name="actions" @slotchange=${this.onSlotActions}></slot>
            </div>
        `;
    }

    render() {
        const cardClass = {
            "card": true,
            "bg-light": this.background === "light",
            "has-footer": this._showFooter,
            "has-badge": !!this.badgeText
        };

        return html`
            <div class="card-wrapper">
                ${this.renderHeroImage()}
                <div class=${classMap(cardClass)}>
                    <div class="card-header">
                        <atlas-heading type="h6">${this.header}</atlas-heading>
                        ${this.renderBadge()} ${this.renderInfoIcon()}
                    </div>
                    <div class="card-body">
                        <slot></slot>
                    </div>
                    ${this.renderFooter()}
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-card": AtlasCard;
    }
}
