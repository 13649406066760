import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { choose } from "lit/directives/choose.js";
import { WithPopoverMixin, WithPopoverProps } from "@/internals/mixins/with-popover-mixin";

import { HeadingType } from "./types";
import { Theme } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-heading.scss";
import "@/components/display/atlas-icon-button/atlas-icon-button";

export type AtlasHeadingProps = AtlasElementProps &
    WithPopoverProps & {
        type: string;
    };

/**
 * Componente de tipografia relacionado aos tipos de titulos e cabeçalhos que existem
 *
 * @prop {string} type - O tipo de heading desejado, dentre os tipos possiveis
 * @prop {string} popover-title - O título que será exibido no popover
 * @prop {string} popover-content - O conteúdo do popover
 *
 * @tag atlas-heading
 */
@customElement("atlas-heading")
export default class AtlasHeading extends WithPopoverMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) type: HeadingType = "h4";

    @property({ type: String }) theme: Theme;

    @property({ type: Boolean }) muted: boolean;

    @property({ type: Boolean }) white: boolean;

    @property({ type: Boolean }) ellipsis: boolean;

    @property({ type: Boolean, attribute: "reset-line-height" }) resetLineHeight: boolean;

    renderHTMLTag() {
        return choose(
            this.type,
            [
                ["h1", () => html`<h1 class="heading-text"><slot></slot></h1>`],
                ["h2", () => html`<h2 class="heading-text"><slot></slot></h2>`],
                ["h3", () => html`<h3 class="heading-text"><slot></slot></h3>`],
                ["h4", () => html`<h4 class="heading-text"><slot></slot></h4>`],
                ["h5", () => html`<h5 class="heading-text"><slot></slot></h5>`],
                ["h6", () => html`<h6 class="heading-text"><slot></slot></h6>`]
            ],
            () => html`<h4 class="heading-text"><slot></slot></h4>`
        );
    }

    renderHeadingIcon() {
        return when(
            this.hasPopover(),
            () => html`
                <atlas-icon-button
                    icon="info"
                    theme="primary"
                    size="2x"
                    popover-title=${this.popoverTitle}
                    popover-content=${this.popoverContent}
                ></atlas-icon-button>
            `
        );
    }

    renderSkeleton() {
        const skeletonClass = {
            [`${this.type}`]: true,
            skeleton: true
        };

        return html` <div class="${classMap(skeletonClass)}"></div> `;
    }

    renderElement() {
        const headingClass = {
            "heading": true,
            "muted": this.muted,
            "white": this.white,
            "ellipsis": this.ellipsis,
            "reset-line-height": this.resetLineHeight,
            [`color-${this.theme}`]: !!this.theme
        };

        return html`<div class=${classMap(headingClass)}>${this.renderHTMLTag()} ${this.renderHeadingIcon()}</div>`;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-heading": AtlasHeading;
    }
}
