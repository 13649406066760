import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import DeviceController from "@/controllers/device-controller";
import { Theme } from "@/internals/theme";

import styles from "./atlas-badge.scss";

export type BadgeProps = {
    "text": string;
    "theme": Theme;
    "is-counter": boolean;
    "show-zero": boolean;
};

/**
 * @prop {string} text - Texto de conteúdo do badge
 * @prop {Theme} theme - Tema do badge
 * @prop {boolean} is-counter - Indica se é um badge de contador
 * @prop {boolean} show-zero - Indica se o badge deve mostrar o valor 0 quando ele for um contador
 *
 * @tag atlas-badge
 */
@customElement("atlas-badge")
export default class AtlasBadge extends LitElement {
    static styles = styles;

    @property({ type: String }) text: string;

    @property({ type: String }) theme: Theme = "danger";

    @property({ type: Boolean, attribute: "is-counter" }) isCounter = false;

    @property({ type: Boolean, attribute: "show-zero" }) showZero = false;

    private deviceController = new DeviceController(this);

    getTextContent(): string {
        if (this.isCounter) {
            let number = parseInt(this.text, 10);

            if (Number.isNaN(number)) {
                number = 0;
            }

            if (!this.showZero && number === 0) {
                return "";
            }

            return number > 9 ? "9+" : `${number}`;
        }

        return this.text;
    }

    render() {
        const textContent = this.getTextContent();

        const badgeClass = {
            "badge": true,
            "small": this.deviceController.isMobile && (this.isCounter || !textContent),
            "counter": this.isCounter,
            "with-text": !!textContent,
            [`bg-${this.theme}`]: !!this.theme
        };

        return html`
            <div class=${classMap(badgeClass)}>
                <span class="content">${textContent}</span>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-badge": AtlasBadge;
    }
}
