import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

import { Watch } from "@/decorators/watch";
import DeviceController from "@/controllers/device-controller";

import type AtlasOnboardingStep from "../atlas-onboarding-step/atlas-onboarding-step";
import styles from "./atlas-onboarding.scss";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-illustration/atlas-illustration";
import "@/components/display/atlas-text/atlas-text";

export type OnboardingProps = {
    "current-step": string;
};

/**
 * @prop {string} current-step - Passo ativo do onboarding
 *
 * @tag atlas-onboarding
 */
@customElement("atlas-onboarding")
export default class AtlasOnboarding extends LitElement {
    static styles = styles;

    @property({ type: String, attribute: "current-step" }) currentStep: string;

    @state() private _steps: AtlasOnboardingStep[];

    @state() private _currentIllustration: string;

    @state() private _stepperLinePosition = { left: "0px", width: "0px" };

    private _deviceController = new DeviceController(this);

    connectedCallback() {
        super.connectedCallback?.();

        this.syncSteps = this.syncSteps.bind(this);
        this.calculateStepperLinePosition = this.calculateStepperLinePosition.bind(this);

        this.addEventListener("atlas-onboarding-step-change", this.syncSteps);
        this._deviceController.setScreenChangeCallback(this.calculateStepperLinePosition);
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-onboarding-step-change", this.syncSteps);
    }

    async calculateStepperLinePosition() {
        await this.updateComplete;

        const stepsElement = this.shadowRoot.querySelectorAll(".step");
        const firstStep = stepsElement[0];
        const lastStep = stepsElement[stepsElement.length - 1];

        const firstStepWidth = firstStep.getBoundingClientRect().width;
        const lastStepWidth = lastStep.getBoundingClientRect().width;

        this._stepperLinePosition = {
            left: `${firstStepWidth / 2}px`,
            width: `calc(100% - ${firstStepWidth / 2 + lastStepWidth / 2}px)`
        };
    }

    syncSteps() {
        this._steps = this.shadowRoot
            .querySelector("slot")
            .assignedElements()
            .map((step: AtlasOnboardingStep) => {
                if (step.name === this.currentStep) {
                    this._currentIllustration = step.illustration;
                }

                return step;
            });

        this.calculateStepperLinePosition();
    }

    @Watch("currentStep")
    async setVisibleStep() {
        await this.updateComplete;

        this._steps.forEach((step) => {
            if (step.name === this.currentStep) {
                step.setVisible(true);
                this._currentIllustration = step.illustration;
            } else {
                step.setVisible(false);
            }
        });
    }

    renderOnboardingSteps() {
        return this._steps?.map((step) => {
            const isActive = step.name === this.currentStep;
            const icon = step.getStatusIcon();
            const theme = step.getStatusTheme();

            const stepClass = {
                step: true,
                active: isActive
            };

            return html`
                <div class=${classMap(stepClass)}>
                    <atlas-icon
                        name=${isActive && step.status === "pending" ? "concentric-circles" : icon}
                        theme=${isActive && step.status === "pending" ? "primary" : theme}
                        size="2x"
                        ?disabled=${!isActive && step.status === "pending"}
                    ></atlas-icon>
                    <atlas-text type="small" ?muted=${!isActive}>${step.label}</atlas-text>
                </div>
            `;
        });
    }

    render() {
        return html`
            <div class="onboarding">
                <div class="onboarding-illustration">
                    <atlas-illustration name=${this._currentIllustration}></atlas-illustration>
                </div>
                <div class="onboarding-content">
                    <slot @slotchange=${this.syncSteps}></slot>
                    <div class="onboarding-stepper-wrapper">
                        <div class="onboarding-stepper">
                            ${this.renderOnboardingSteps()}
                            <div class="stepper-line" style=${styleMap(this._stepperLinePosition)}></div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-onboarding": AtlasOnboarding;
    }
}
