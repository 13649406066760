import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { TextType } from "./types";
import { Theme, ThemeVariation } from "@/internals/theme";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-text.scss";

export type AtlasTextProps = AtlasElementProps & {
    "type": string;
    "theme": Theme;
    "theme-variation": ThemeVariation;
    "bold": boolean;
    "italic": boolean;
    "underline": boolean;
    "strikethrough": boolean;
    "muted": boolean;
    "white": boolean;
};

/**
 * Componente de tipografia relacionado aos tipos de texto que existem
 *
 * @prop {string} type - O tipo de text desejado, dentre os tipos possiveis
 * @prop {Theme} theme - Cor do texto
 * @prop {ThemeVariation} theme-variation - A variação da tonalidade da cor do tema
 * @prop {boolean} bold - Apenas aplicavel ao tipo paragraph, define um texto em negrito
 * @prop {boolean} italic - Apenas aplicavel ao tipo paragraph, define um texto em itálico
 * @prop {boolean} underline - Apenas aplicavel ao tipo paragraph, define um texto sublinhado
 * @prop {boolean} strikethrough - Apenas aplicavel ao tipo paragraph, define um texto riscado
 * @prop {boolean} muted - Indica se o texto terá uma cor mais clara
 * @prop {boolean} white - Indica se o texto será branco
 *
 * @tag atlas-text
 */
@customElement("atlas-text")
export default class AtlasText extends AtlasElement {
    static styles = styles;

    @property({ type: String }) type: TextType = "paragraph";

    @property({ type: String }) theme: Theme;

    @property({ type: Number, attribute: "theme-variation" }) themeVariation: ThemeVariation;

    @property({ type: Boolean }) bold = false;

    @property({ type: Boolean }) italic = false;

    @property({ type: Boolean }) underline = false;

    @property({ type: Boolean }) strikethrough = false;

    @property({ type: Boolean }) muted = false;

    @property({ type: Boolean }) white = false;

    getThemeClass() {
        if (this.theme) {
            if (this.themeVariation) {
                return `color-${this.theme}-${this.themeVariation}`;
            }

            return `color-${this.theme}`;
        }

        return "";
    }

    renderSkeleton() {
        const skeletonClass = {
            [`${this.type}`]: true,
            skeleton: true
        };

        return html` <div class="${classMap(skeletonClass)}"></div> `;
    }

    renderElement() {
        const themeClass = this.getThemeClass();

        const textClass = {
            [`${this.type}`]: true,
            [themeClass]: !!themeClass,
            italic: this.italic,
            underline: this.underline,
            bold: this.bold,
            strikethrough: this.strikethrough,
            muted: this.muted,
            white: this.white
        };

        return html`
            <span class="${classMap(textClass)}">
                <slot></slot>
            </span>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-text": AtlasText;
    }
}
