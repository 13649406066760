import { html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { Theme } from "@/internals/theme";
import { emit } from "@/internals/events";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-calendar-timeline-item.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type CalendarTimelineItemProps = AtlasElementProps & {
    "label": string;
    "description": string;
    "icon": string;
    "icon-theme": Theme;
    "href": string;
    "atlas-timeline-item-click": Function;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {string} label - Label do item
 * @prop {string} description - Descrição do item
 * @prop {string} icon - Ícone que aparece do lado esquerdo do item
 * @prop {Theme} icon-theme - Tema do ícone
 * @prop {string} href - Link para qual o usuário será redirecionado ao clicar no item
 *
 * @event {CustomEvent} atlas-timeline-item-click - Evento disparado quando é feito o clique no item
 *
 * @tag atlas-calendar-timeline-item
 */
@customElement("atlas-calendar-timeline-item")
export default class AtlasCalendarTimelineItem extends AtlasElement {
    static styles = styles;

    @property({ type: String }) label: string;

    @property({ type: String }) description: string;

    @property({ type: String }) icon: string;

    @property({ type: String, attribute: "icon-theme" }) iconTheme: Theme;

    @property({ type: String }) href: string;

    handleItemClick() {
        if (this.href) {
            window.open(this.href, "_self");
        }

        emit(this, "atlas-timeline-item-click");
    }

    renderElement() {
        return html`
            <button class="timeline-item" @click=${this.handleItemClick}>
                <atlas-icon
                    name=${this.icon}
                    theme=${this.iconTheme}
                    size="3x"
                    theme-variation=${this.iconTheme === "warning" ? 700 : 500}
                ></atlas-icon>
                <div class="timeline-item-content">
                    <atlas-text type="small">${this.label}</atlas-text>
                    <atlas-heading type="h5">${this.description}</atlas-heading>
                </div>
                <atlas-icon name="chevron-right" theme="secondary" size="3x"></atlas-icon>
            </button>
        `;
    }

    renderSkeleton() {
        return html`
            <div class="timeline-item">
                <atlas-heading type="h5"></atlas-heading>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-calendar-timeline-item": AtlasCalendarTimelineItem;
    }
}
