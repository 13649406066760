import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import styles from "./atlas-critical-action.scss";

import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-icon/atlas-icon";

export type CriticalActionProps = WithCollapseProps & {
    header: string;
    blocked: boolean;
};

/**
 * @dependency atlas-text
 * @dependency atlas-icon
 *
 * @prop {string} header - Define qual será o texto do cabeçalho do componente
 * @prop {boolean} blocked - Define quando a ação crítica está bloqueada
 *
 * @tag atlas-critical-action
 */
@customElement("atlas-critical-action")
export default class AtlasCriticalAction extends WithCollapseMixin(LitElement) {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Boolean }) blocked: boolean = false;

    renderHeader() {
        return when(
            !!this.header,
            () => html`
                <div class="critical-action-header">
                    <atlas-icon name="${this.blocked ? "x" : "alert-triangle"}"></atlas-icon>
                    <atlas-text bold>${this.header}</atlas-text>
                    <slot name="header-action"></slot>
                    ${when(this.collapsible && !this.blocked, () => this.renderCollapseButton("warning"))}
                </div>
            `
        );
    }

    renderContent() {
        return this.renderContentWithCollapse(html`
            <div class="critical-action-content">
                <slot></slot>
            </div>
        `);
    }

    render() {
        return html`
            <div class="critical-action-container">
                ${this.renderHeader()} ${this.renderContent()} ${when(!this.blocked, () => this.renderContent())}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-critical-action": AtlasCriticalAction;
    }
}
