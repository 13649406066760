import { LitElement, html, HTMLTemplateResult } from "lit";
import { property, state } from "lit/decorators.js";

import { Constructor } from "@/internals/basic-types";

import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import "@/components/display/atlas-collapse-button/atlas-collapse-button";
import "@/components/layout/atlas-collapse/atlas-collapse";

export type WithCollapseProps = {
    collapsible: boolean;
};

export declare class WithCollapseInterface {
    collapsible: boolean;

    _collapsed: boolean;

    toggleCollapse(): void;

    renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult;

    renderCollapseButton(theme?: Theme): HTMLTemplateResult;
}

export const WithCollapseMixin = <T extends Constructor<LitElement>>(superClass: T) => {
    /**
     * @dependency atlas-collapse
     * @dependency atlas-collapse-button
     *
     * @prop {boolean} collapsible - Indica se o elemento possui o estado de colapsável
     */
    class WithCollapseClass extends superClass {
        @property({ type: Boolean }) collapsible: boolean;

        @state() _collapsed: boolean;

        toggleCollapse(event: CustomEvent): void {
            event.stopPropagation();

            this._collapsed = !this._collapsed;

            emit(this, "atlas-collapse-button-click", {
                detail: {
                    collapsed: this._collapsed
                }
            });
        }

        renderContentWithCollapse(template: HTMLTemplateResult): HTMLTemplateResult {
            return html`
                <atlas-collapse ?show=${this.collapsible ? !this._collapsed : true}> ${template} </atlas-collapse>
            `;
        }

        renderCollapseButton(theme?: Theme): HTMLTemplateResult {
            return html`
                <atlas-collapse-button
                    theme=${theme || "primary"}
                    @click=${this.toggleCollapse}
                    ?collapsed=${this._collapsed}
                ></atlas-collapse-button>
            `;
        }
    }

    return WithCollapseClass as Constructor<WithCollapseInterface> & T;
};
