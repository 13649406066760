import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { INPUT_ELEMENTS, CHECK_ELEMENTS, SELECT_ELEMENTS } from "@/helpers/form";

import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";

import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

import type FormElement from "@/components/form/form-element";

import AtlasElementGroup, { ElementGroupProps } from "@/components/layout/atlas-element-group/atlas-element-group";

import styles from "./atlas-filter-group.scss";
import "@/components/display/atlas-heading/atlas-heading";

export type FilterGroupProps = WithCollapseProps &
    ElementGroupProps & {
        "header": string;
    };

/**
 * @dependency atlas-heading
 *
 * @prop {string} header - Define o texto que dará nome ao grupo
 * @prop {string} name - Propriedade name que irá ser compartilhada entre os filhos
 * @prop {boolean} disabled - Propriedade que desabilita o group
 * @prop {boolean} collapsible - Define se o group é expansível/colapsável
 * @prop {number} required-fields - Numero de filhos requeridos para uma certa validação
 * @prop {string} required-fields-error-message - A mensagem que será exibida se o número de filhos requeridos não chegar ao número escolhido
 *
 * @tag atlas-filter-group
 */
@customElement("atlas-filter-group")
export default class AtlasFilterGroup extends WithCollapseMixin(AtlasElementGroup) {
    static styles = styles;

    @property({ type: String }) header: string;

    resetFilterElements() {
        /* eslint-disable no-param-reassign */
        this.slottedElements.forEach((slotted: LitElement) => {
            const tagName = slotted.tagName.toLowerCase();

            if (INPUT_ELEMENTS.includes(tagName)) {
                const inputElement = slotted as FormElement;
                inputElement.value = "";
            }

            if (CHECK_ELEMENTS.includes(tagName)) {
                const checkElement = slotted as AtlasCheckbox | AtlasRadio;
                checkElement.uncheck();
            }
        });
        /* eslint-enable no-param-reassign */
    }

    setFilterElementsValues(filterValues: Array<string>) {
        /* eslint-disable no-param-reassign */
        this.slottedElements.forEach((slotted: LitElement) => {
            const tagName = slotted.tagName.toLowerCase();

            if (CHECK_ELEMENTS.includes(tagName)) {
                const checkElement = slotted as AtlasCheckbox | AtlasRadio;

                if (filterValues.includes(checkElement.value)) {
                    checkElement.check();
                } else {
                    checkElement.uncheck();
                }
            }

            if ([...SELECT_ELEMENTS, ...INPUT_ELEMENTS].includes(tagName)) {
                const selectElement = slotted as FormElement;
                const value = filterValues[0];

                selectElement.value = value;
            }
        });
        /* eslint-enable no-param-reassign */
    }

    getFilterWidth() {
        const elementRect = this.shadowRoot.querySelector(".atlas-filter-group").getBoundingClientRect();

        return `width: ${elementRect.width}px`;
    }

    renderValidationMessage() {
        return when(
            !this._valid,
            () => html`
                <atlas-text type="small" theme="danger" class="invalid-feedback" style=${this.getFilterWidth()}>
                    ${this._errorMessage}
                </atlas-text>
            `
        );
    }

    render() {
        const elementGroupClass = {
            "atlas-filter-group": true,
            "is-invalid": !this._valid,
            "with-header": !!this.header
        };

        const layoutClass = {
            "atlas-filter-group-content": true,
            "atlas-layout": true,
            "inline": this.getIsInline(),
            "mobile": this._deviceController.isMobile,
            "fluid": this.fluid,
            [`align-${this.alignment}`]: !!this.alignment,
            [`justify-${this.justify}`]: !!this.justify,
            [`gap-${this.gap}`]: !!this.gap
        };

        return html`
            <div class=${classMap(elementGroupClass)}>
                <div class="atlas-filter-group-header">
                    ${when(!!this.header, () => html` <atlas-heading type="h6">${this.header}</atlas-heading> `)}
                    ${when(this.collapsible, () => this.renderCollapseButton())}
                </div>

                ${this.renderContentWithCollapse(html`
                    <div class="${classMap(layoutClass)}">
                        <slot @slotchange=${this.onSlotChange}></slot>
                    </div>
                `)}
            </div>
            ${this.renderValidationMessage()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter-group": AtlasFilterGroup;
    }
}
