import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";

import styles from "./atlas-onboarding-step.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";

const STATUS_MAP = {
    pending: {
        icon: "circle",
        text: "Pendente",
        theme: "secondary"
    },
    review: {
        icon: "clock",
        text: "Em análise",
        theme: "warning"
    },
    approved: {
        icon: "check-circle",
        text: "",
        theme: "success"
    },
    attention: {
        icon: "alert-circle",
        text: "Precisa de atenção",
        theme: "danger"
    }
};

export type OnboardingStepProps = {
    "name": string;
    "label": string;
    "header": string;
    "status": "pending" | "review" | "approved" | "attention";
    "status-text": string;
    "status-theme": string;
    "illustration": string;
};

/**
 * @prop {string} name - Nome do passo
 * @prop {string} label - Label que aparece no stepper do onboarding
 * @prop {string} header - Título do passo
 * @prop {string} status - Situação do passo (Serve para definir o ícone que aparece ao lado da label além do texto e tema padrão da situação)
 * @prop {string} status-text - Texto de situação do passo (Por padrão cada situação mostra um texto diferente, esse atributo serve para personalizar o texto)
 * @prop {string} status-theme - Tema da situação do passo (Por padrão cada situação mostra um texto com sua cor respectiva, esse atributo serve para personalizar a cor do texto)
 * @prop {string} illustration - Ilustração que representa o passo do onboarding
 *
 * @emit {CustomEvent} atlas-onboarding-step-change - Evento disparado ao mudar a situação do passo
 *
 * @tag atlas-onboarding-step
 */
@customElement("atlas-onboarding-step")
export default class AtlasOnboardingStep extends LitElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String }) label: string;

    @property({ type: String }) header: string;

    @property({ type: String }) status: "pending" | "review" | "approved" | "attention" = "pending";

    @property({ type: String, attribute: "status-text" }) statusText: string;

    @property({ type: String, attribute: "status-theme" }) statusTheme: Theme;

    @property({ type: String }) illustration: string;

    @state() private _visible = false;

    setVisible(visible: boolean) {
        this._visible = visible;
    }

    getStatusIcon() {
        return STATUS_MAP[this.status]?.icon;
    }

    getStatusTheme() {
        return this.statusTheme || STATUS_MAP[this.status]?.theme;
    }

    @Watch(["status", "illustration"])
    onStatusChange() {
        emit(this, "atlas-onboarding-step-change");
    }

    renderStatusText() {
        const statusText = this.statusText || STATUS_MAP[this.status]?.text;
        const statusTheme = this.getStatusTheme();

        if (!statusText) return null;

        const stepStatusClass = {
            "status-text": true,
            [`color-${statusTheme}`]: !!statusTheme
        };

        return html`<span class=${classMap(stepStatusClass)}>${statusText}</span>`;
    }

    render() {
        const stepClass = {
            "onboarding-step": true,
            "visible": this._visible
        };

        return html`
            <div class=${classMap(stepClass)}>
                <div class="step-title">
                    <atlas-heading type="h5">${this.header}</atlas-heading>
                    ${this.renderStatusText()}
                </div>
                <div class="step-content">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-onboarding-step": AtlasOnboardingStep;
    }
}
