import { html } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";
import { ifDefined } from "lit/directives/if-defined.js";

import EmojiValidator from "@/internals/validators/emoji-validator";
import FormControl from "@/components/form/form-control";
import { Watch } from "@/decorators/watch";
import { InputSize } from "@/components/form/atlas-input/types";
import { emit } from "@/internals/events";

import textAreaStyle from "./atlas-textarea.scss";

/**
 * @prop {string} id - O id do textarea, usado para fazer associações de ARIA
 * @prop {string} name - O nome do textarea
 * @prop {string} value - O valor do inicial do textarea
 * @prop {string} label - O tamanho do textarea
 * @prop {string} label - A label que ficará acima do textarea determinando o nome do mesmo
 * @prop {string} placeholder - A mensagem que aparecerá quando o textarea está vazio
 * @prop {string} helper-text - O texto auxiliar que pode ser utilizado no textarea
 * @prop {number} rows - Especifica a altura visível de um textarea, em linhas.
 * @prop {number} maxlength - O tamanho máximo de caracteres do textarea
 * @prop {boolean} required - Indica se o textarea é obrigatório
 * @prop {boolean} disabled - Indica se o textarea está desabilitado
 * @prop {boolean} readonly - Indica se o textarea é apenas para leitura
 * @prop {boolean} resize - Indica se o textarea vai ter resize (vertical) ou não
 * @prop {boolean} enable-emoji - Indica se o input pode conter emoji
 *
 * @event {CustomEvent} atlas-textarea-focus - Evento lançado quando é dado foco no textarea
 * @event {CustomEvent} atlas-textarea-blur - Evento lançado quando é retirado o foco do textarea
 * @event {CustomEvent} atlas-textarea-change - Evento lançado quando é o valor do textarea é alterado
 *
 * @tag atlas-textarea
 */
@customElement("atlas-textarea")
export default class AtlasTextarea extends FormControl {
    static styles = textAreaStyle;

    @property({ type: String }) size: InputSize = "md";

    @property({ type: String }) placeholder = "";

    @property({ type: Number }) rows = 4;

    @property({ type: Number }) maxlength: number;

    @property({ type: Boolean }) readonly = false;

    @property({ type: Boolean }) resize = false;

    @property({ type: Boolean, attribute: "enable-emoji" }) enableEmoji = false;

    @query("textarea") textareaRef: HTMLTextAreaElement;

    connectedCallback(): void {
        if (!this.enableEmoji) {
            this.addValidator(new EmojiValidator());
        }

        super.connectedCallback?.();
    }

    focus() {
        this.textareaRef.focus();
    }

    blur() {
        this.textareaRef.blur();
    }

    handleFocus() {
        emit(this, "atlas-textarea-focus");
    }

    handleBlur() {
        this.reportValidity();

        emit(this, "atlas-textarea-blur");
    }

    handleInput() {
        this.value = this.textareaRef.value;
    }

    @Watch("value")
    onChangeValue() {
        emit(this, "atlas-textarea-change", { detail: this.value });
    }

    renderTextarea() {
        const ariaLabel = this.disabled || this.readonly ? this.placeholder : "";
        const ariaDescribedBy = this.helperText ? `${this.id}-helper` : null;

        const inputClass = {
            "form-control": true,
            "form-control-sm": this.size === "sm",
            "form-control-lg": this.size === "lg",
            "resizable": this.resize,
            "is-invalid": this._showValidationState && !this._valid
        };

        return html`
            <textarea
                aria-describedby="${ifDefined(ariaDescribedBy)}"
                aria-label="${ariaLabel}"
                id="${this.id}"
                name="${this.name}"
                class=${classMap(inputClass)}
                placeholder="${this.placeholder}"
                rows=${ifDefined(this.rows || undefined)}
                maxlength="${ifDefined(this.maxlength)}"
                .value=${live(this.value)}
                ?disabled=${this.disabled}
                ?readonly=${this.readonly}
                ?required=${this.required}
                @focus=${this.handleFocus}
                @blur=${this.handleBlur}
                @input=${this.handleInput}
                autocomplete="off"
            >
            </textarea>
            ${this.renderValidationMessage()}
        `;
    }

    render() {
        return html` ${this.renderLabel()} ${this.renderTextarea()} ${this.renderHelperText()} `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-textarea": AtlasTextarea;
    }
}
