import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import illustrations from "./illustrations";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-illustration.scss";

export type IllustrationProps = AtlasElementProps & {
    name: string;
    alt: string;
};

/**
 * @prop {string} name - Nome da ilustração
 * @prop {string} alt - Descrição da ilustração (Para tecnologias assistivas)
 *
 * @tag atlas-illustration
 */
@customElement("atlas-illustration")
export default class AtlasIllustration extends AtlasElement {
    static styles = styles;

    @property({ type: String }) name: string;

    @property({ type: String }) alt: string;

    render() {
        const illustrationClass = {
            "atlas-illustration": true,
            "skeleton": this.skeletonLoading
        };

        return html`
            <div class=${classMap(illustrationClass)}>
                <img src="${illustrations[this.name]}" alt="${this.alt}" />
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-illustration": AtlasIllustration;
    }
}
