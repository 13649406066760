import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import FormControl, { FormControlProps } from "@/components/form/form-control";
import { Watch } from "@/decorators/watch";
import { debounce } from "@/internals/debounce";
import { emit } from "@/internals/events";
import { get } from "@/helpers/request";

import { InputSize } from "@/components/form/atlas-input/types";
import { SelectOption } from "./types";
import type AtlasSelectDropdown from "./atlas-select-dropdown";
import type AtlasInput from "@/components/form/atlas-input/atlas-input";
import type AtlasOption from "@/components/form/atlas-option/atlas-option";

import "./atlas-select-dropdown";
import "./atlas-select-tags";
import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-select-item/atlas-select-item";

export type SelectProps = FormControlProps & {
    "size": InputSize;
    "placeholder": string;
    "loading": boolean;
    "multiple": boolean;
    "enable-new": boolean;
    "new-item-prefix": string;
    "empty-state-text": string;
    "search-url": string;
    "search-params": string;
    "value-key": string;
    "label-key": string;
    "extra-keys": string;
};

/**
 * @dependency atlas-select-dropdown
 * @dependency atlas-select-tags
 * @dependency atlas-input
 * @dependency atlas-select-item
 *
 * @prop {InputSize} size - O tamanho do select
 * @prop {string} placeholder - A mensagem que aparecerá quando o select está vazio
 * @prop {boolean} loading - Indica se o select é está em estado de loading
 * @prop {boolean} multiple - Indica se multiplos valores podem ser selecionados
 * @prop {boolean} enable-new - Indica se novos valores podem ser adicionados pelo próprio select
 * @prop {string} new-item-prefix - Prefixo da mensagem que aparece na opção de adicionar um novo valor
 * @prop {string} empty-state-text - Mensagem que é exibida no dropdown quando o select não possui opções
 * @prop {string} search-url - URL de onde os dados do select serão buscados (Isso habilita o input do select, permitindo digitar no campo para os resultados serem filtrados)
 * @prop {string} search-params - Parâmetros que serão enviados para o backend para serem utilizados como filtro, separados por ";"
 * @prop {string} value-key - Chave do valor da opção que o backend vai retornar (O valor da opção quando ela for selecionada)
 * @prop {string} label-key - Chave do nome da opção que o backend vai retornar (O que será exibido nas opções do select)
 * @prop {string} extra-keys - Chave dos conteúdos extras que serão exibidos na opção do select, separados por ";"
 * @prop {string} groups - Objeto contendo o nome e a chave dos grupos do select
 *
 * @slot default - Slot padrão usado para definir as opções do select
 *
 * @event {CustomEvent} atlas-select-change - Evento disparado quando o valor do select é alterado
 *
 * @tag atlas-select
 */
@customElement("atlas-select")
export default class AtlasSelect extends FormControl {
    @property({ type: String }) size: InputSize = "md";

    @property({ type: String }) placeholder: string;

    @property({ type: Boolean }) loading: boolean;

    @property({ type: Boolean }) multiple: boolean;

    @property({ type: Boolean, attribute: "enable-new" }) enableNew: boolean;

    @property({ type: String, attribute: "new-item-prefix" }) newItemPrefix: string = "Criar";

    @property({ type: String, attribute: "empty-state-text" }) emptyStateText: string = "Sem escolhas para fazer";

    @property({ type: String, attribute: "search-url" }) searchUrl: string;

    @property({ type: String, attribute: "search-params" }) searchParams: string = "q";

    @property({ type: String, attribute: "value-key" }) valueKey: string;

    @property({ type: String, attribute: "label-key" }) labelKey: string;

    @property({ type: String, attribute: "extra-keys" }) extraKeys: string;

    @property({ type: Object }) groups: { [key: string]: string } = {};

    @state() private _isDropdownOpen = false;

    @state() private _syncInputValueOnNextChange = true;

    @state() private _showLoadingOnSearch = true;

    @state() private _inputValue = "";

    @state() private _lastInputValue = "";

    @state() private _selectOptions: SelectOption[] = [];

    @state() private _selected: SelectOption[] = [];

    @state() private _countNewOptions = 0;

    @query(".atlas-select-input")
    private _selectInput: AtlasInput;

    @query("atlas-select-dropdown")
    private _selectDropdown: AtlasSelectDropdown;

    async connectedCallback() {
        await super.connectedCallback?.();
        await this.updateComplete;

        this.onSelectDropdownChange = this.onSelectDropdownChange.bind(this);
        this.onCreateNewOption = this.onCreateNewOption.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputFocus = this.onInputFocus.bind(this);
        this.onInputKeyDown = this.onInputKeyDown.bind(this);
        this.onSelectDropdownInputKeyDown = this.onSelectDropdownInputKeyDown.bind(this);
        this.onTagRemove = this.onTagRemove.bind(this);
        this.onDropdownOpen = this.onDropdownOpen.bind(this);
        this.onDropdownClose = this.onDropdownClose.bind(this);
        this.searchOptions = debounce(this.searchOptions.bind(this), 350);

        this.addEventListener("atlas-select-dropdown-change", this.onSelectDropdownChange);
        this.addEventListener("atlas-select-dropdown-create-new", this.onCreateNewOption);
        this.addEventListener("atlas-select-tags-remove", this.onTagRemove);
        this.addEventListener("atlas-select-dropdown-search", this.onInputChange);
        this.addEventListener("atlas-select-dropdown-input-keydown", this.onSelectDropdownInputKeyDown);
        this.addEventListener("atlas-dropdown-opened", this.onDropdownOpen);
        this.addEventListener("atlas-dropdown-closed", this.onDropdownClose);
        this.searchOptions();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-select-dropdown-change", this.onSelectDropdownChange);
        this.removeEventListener("atlas-select-dropdown-create-new", this.onCreateNewOption);
        this.removeEventListener("atlas-select-tags-remove", this.onTagRemove);
        this.removeEventListener("atlas-select-dropdown-search", this.onInputChange);
        this.removeEventListener("atlas-select-dropdown-input-keydown", this.onSelectDropdownInputKeyDown);
        this.removeEventListener("atlas-dropdown-opened", this.onDropdownOpen);
        this.removeEventListener("atlas-dropdown-closed", this.onDropdownClose);
    }

    @Watch(["_valid", "_validationMessage", "_showValidationState"])
    async syncInputValidation() {
        await this.updateComplete;

        this._selectInput._validationMessage = this._validationMessage;
        this._selectInput._valid = this._valid;
        this._selectInput._showValidationState = this._showValidationState;
    }

    isSelectSearch() {
        return !!this.searchUrl;
    }

    getSelectedValues() {
        return `${this.value}`.split(",");
    }

    getSelectedOptions() {
        if (this.multiple) {
            return this._selected;
        }

        return this._selected[0] || {};
    }

    focus() {
        this._selectInput.focus();
        this._selectDropdown.openDropdown();
    }

    blur() {
        this._selectInput.blur();
        this._selectDropdown.closeDropdown();
    }

    syncTextWithSelectedValue() {
        if (this.multiple) {
            this._inputValue = "";
        } else {
            this._inputValue = this._selected?.[0]?.label || "";
        }
    }

    onSelectDropdownChange(event: CustomEvent) {
        const { option } = event.detail;

        if (this.multiple && this.value) {
            this.selectOption(`${this.value},${option}`);
        } else {
            this.selectOption(`${option}`);
        }
    }

    onCreateNewOption(event: CustomEvent) {
        const { optionLabel } = event.detail;

        this._countNewOptions += 1;

        this.selectOption([
            ...this._selected,
            {
                label: optionLabel,
                value: `${-this._countNewOptions}`
            }
        ]);
    }

    onInputChange(event: CustomEvent) {
        this._lastInputValue = this._inputValue;
        this._inputValue = event.detail;
        this.searchOptions();
    }

    onInputFocus() {
        this._selectInput._input.select();
    }

    onInputKeyDown() {
        this._selectDropdown.openDropdown();

        setTimeout(() => {
            if (!this.multiple && this._inputValue !== this._lastInputValue) {
                this._syncInputValueOnNextChange = false;
                this.value = "";
            }
        }, 0);
    }

    onSelectDropdownInputKeyDown() {
        if (!this.multiple) {
            this._syncInputValueOnNextChange = false;
            this.value = "";
        }
    }

    onTagRemove(event: CustomEvent) {
        const { option } = event.detail;
        const newValue = this.getSelectedValues()
            .filter((value) => option !== value)
            .join(",");

        this.value = newValue;
    }

    onDropdownOpen() {
        this._isDropdownOpen = true;
    }

    onDropdownClose() {
        this._isDropdownOpen = false;

        if (!this.value) {
            this._inputValue = "";
        }

        this.reportValidity();
    }

    @Watch("value", true)
    async onChangeValue() {
        await this.updateComplete;

        const selectedValues = this.getSelectedValues();
        this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));

        this._showLoadingOnSearch = false;

        if (this._syncInputValueOnNextChange) {
            this.syncTextWithSelectedValue();
        }

        this._syncInputValueOnNextChange = true;
        emit(this, "atlas-select-change");
    }

    loadSlottedOptions() {
        const options = this.shadowRoot
            .querySelector("slot")
            .assignedElements()
            .map((option: AtlasOption) => ({
                label: option.label,
                value: option.value,
                disabled: option.disabled,
                group: option.group,
                customProperties: { ...option.dataset }
            }));

        this.setOptions(options);
    }

    setOptions(options: SelectOption[]) {
        const selectedValues = this.getSelectedValues();

        this._selectOptions = options;
        this._selected = this._selectOptions.filter((option) => selectedValues.includes(`${option.value}`));

        this.syncTextWithSelectedValue();
    }

    hasOptionOnSelect(option: SelectOption) {
        return this._selectOptions.some((curOption) => `${curOption.value}` === `${option.value}`);
    }

    createOptionIfNeeded(option: SelectOption) {
        if (!this.hasOptionOnSelect(option)) {
            this._selectOptions = [option, ...this._selectOptions];
        }
    }

    selectOption(option: string | string[] | SelectOption | SelectOption[]) {
        const optionValue: string[] = [];

        if (Array.isArray(option)) {
            option.forEach((opt) => {
                if (typeof opt !== "string") {
                    this.createOptionIfNeeded(opt);
                    optionValue.push(opt.value);
                } else {
                    optionValue.push(opt);
                }
            });
        } else if (typeof option !== "string") {
            this.createOptionIfNeeded(option);
            optionValue.push(option.value);
        } else {
            optionValue.push(option);
        }

        this._syncInputValueOnNextChange = true;
        this.value = optionValue.join(",");
    }

    extractOptionsFromGroups(response: any) {
        const groupsKeys = Object.keys(this.groups);
        const options: SelectOption[] = [];

        if (groupsKeys.length > 0) {
            groupsKeys.forEach((groupName: string) => {
                response[groupName].forEach((option: { [key: string]: any }) => {
                    options.push({
                        label: option[this.labelKey],
                        value: option[this.valueKey],
                        selected: `${option[this.valueKey]}` === `${this.value}`,
                        group: groupName,
                        customProperties: { ...option }
                    });
                });
            });
        } else {
            response.forEach((option: { [key: string]: any }) => {
                options.push({
                    label: option[this.labelKey],
                    value: option[this.valueKey],
                    selected: `${option[this.valueKey]}` === `${this.value}`,
                    customProperties: { ...option }
                });
            });
        }

        return options;
    }

    searchOptions() {
        if (!this.isSelectSearch()) return;

        this.loading = true;

        const searchParams = this.searchParams?.split(";") || ["q"];
        const params = searchParams.reduce((prev, cur) => ({ ...prev, [cur]: this._inputValue }), {});

        const request = get(this.searchUrl, params);

        request.then((response) => {
            this._selectOptions = this.extractOptionsFromGroups(response);

            if (this._selected.length > 0) {
                this._selected.forEach((selected) => this.createOptionIfNeeded(selected));
            }

            setTimeout(() => {
                this.loading = false;
                this._showLoadingOnSearch = true;

                setTimeout(() => {
                    this._selectDropdown.updateDropdownPosition();
                    this._selectDropdown.setFirstFocus();
                }, 0);
            }, 350);
        });
    }

    getVisibleOptions() {
        const selectedValues = this.getSelectedValues();
        let options = this._selectOptions;

        if (!this.isSelectSearch() && (this.value.length === 0 || this.multiple)) {
            options = options.filter((opt) => opt.label.toUpperCase().includes(this._inputValue.toUpperCase()));
        }

        if (this.multiple) {
            options = options.filter((opt) => !selectedValues.includes(`${opt.value}`));
        }

        return options;
    }

    render() {
        return html`
            <atlas-input
                class="atlas-select-input"
                label=${this.label}
                size=${this.size}
                placeholder=${this.placeholder}
                icon=${this._isDropdownOpen ? "chevron-up" : "chevron-down"}
                value=${this._inputValue}
                ?disabled=${this.disabled}
                ?loading=${this._showLoadingOnSearch && this.loading}
                ?hide-optional=${this.required}
                helper-text=${this.helperText}
                popover-title=${this.popoverTitle}
                popover-content=${this.popoverContent}
                data-atlas-dropdown="select-dropdown"
                @atlas-input-change=${this.onInputChange}
                @atlas-input-focus=${this.onInputFocus}
                @keydown=${this.onInputKeyDown}
                ignore-validations
            ></atlas-input>
            <atlas-select-tags ?multiple=${this.multiple} .selectedOptions=${this._selected}></atlas-select-tags>
            <atlas-select-dropdown
                header=${this.label}
                ?loading=${this.loading}
                ?multiple=${this.multiple}
                ?enable-new=${this.enableNew}
                new-item-prefix=${this.newItemPrefix}
                .options=${this.getVisibleOptions()}
                .selectedOptions=${this._selected}
                search-value=${this._inputValue}
                search-placeholder=${this.placeholder}
                empty-state-text=${this.emptyStateText}
                extra-keys=${this.extraKeys}
                .groups=${this.groups}
            ></atlas-select-dropdown>
            <slot @slotchange=${this.loadSlottedOptions}></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-select": AtlasSelect;
    }
}
