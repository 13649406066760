import { html } from "lit";
import { customElement, query, queryAssignedElements, state } from "lit/decorators.js";

import FormControl from "@/components/form/form-control";
import AtlasToggleItem from "@/components/form/atlas-toggle-item/atlas-toggle-item";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import styles from "./atlas-toggle.scss";

/**
 * Componente de toggle utilizado quando queremos que existam opções independentes que alteram algum escopo
 *
 * @prop {string} id - O id do elemento, usado para fazer associações de ARIA
 * @prop {string} name - O nome do elemento
 * @prop {string} value - O valor inicial (deve ser o valor da opção que irá vir marcada)
 * @prop {boolean} disabled - Indica se o elemento está desabilitado
 * @prop {boolean} required - Indica se o elemento é obrigatório
 * @prop {string} helper-text - Propriedade que pode ser enviada se o texto informativo for único para todas as opções
 *
 * @tag atlas-toggle
 */
@customElement("atlas-toggle")
export default class AtlasToggle extends FormControl {
    static styles = styles;

    @state() selectedItem: AtlasToggleItem;

    @queryAssignedElements({ selector: "atlas-toggle-item", flatten: true })
    protected toggleItems!: Array<AtlasToggleItem>;

    @query(".toggle-input")
    private toggleInput: HTMLInputElement;

    constructor() {
        super();

        this.toggleActiveItem = this.toggleActiveItem.bind(this);
    }

    connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("atlas-toggle-item-check", this.toggleActiveItem);
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-toggle-item-check", this.toggleActiveItem);
    }

    getAssociatedElement(): HTMLInputElement {
        return this.toggleInput;
    }

    setPropertiesOnSlotChange() {
        this.syncValue();

        if (this.disabled) {
            this.syncDisabled();
        }
    }

    toggleActiveItem(event: CustomEvent) {
        this.value = (event.target as AtlasToggleItem).value;
    }

    setSelectedItem(selectedItem: AtlasToggleItem) {
        this.selectedItem = selectedItem;
        this.toggleInput.value = selectedItem.value;
        this.helperText = selectedItem.helperText;
    }

    @Watch("value", true)
    syncValue() {
        this.toggleItems.forEach((item) => {
            /* eslint-disable no-param-reassign */
            if (this.value === item.value) {
                this.setSelectedItem(item);
                item.checked = true;
            } else {
                item.checked = false;
            }
            /* eslint-enable no-param-reassign */
        });

        emit(this, "atlas-toggle-change");
    }

    @Watch("disabled", true)
    syncDisabled() {
        this.toggleItems.forEach((item) => {
            item.toggleAttribute("disabled", this.disabled);
        });
    }

    render() {
        return html`
            <div class="atlas-form-pill">
                <input type="text" class="toggle-input" ?required=${this.required} />
                <div class="btn-group">
                    <slot @slotchange=${this.setPropertiesOnSlotChange}></slot>
                </div>
                ${this.renderHelperText(true)}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toggle": AtlasToggle;
    }
}
