import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Theme } from "@/internals/theme";

import styles from "./atlas-collapse-button.scss";
import "@/components/display/atlas-icon/atlas-icon";

@customElement("atlas-collapse-button")
export class AtlasCollapseButton extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) collapsed: boolean;

    @property({ type: String }) size: string = "3x";

    @property({ type: String }) theme: Theme = "primary";

    render() {
        const collapseButtonClass = {
            "collapse-button": true,
            "collapsed": this.collapsed,
            [`theme-${this.theme}`]: !!this.theme
        };

        return html`
            <button type="button" class="${classMap(collapseButtonClass)}">
                <atlas-icon name="chevron-up" size="${this.size}" class="collapse-icon"></atlas-icon>
            </button>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-collapse-button": AtlasCollapseButton;
    }
}
