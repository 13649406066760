import { customElement, property } from "lit/decorators.js";

import Inputmask from "@/vendors/inputmask-utils";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import RangeValidator from "@/internals/validators/range-validator";

export type IntegerInputProps = InputProps & {
    "max-value": number;
    "max-value-error-message": string;
    "min-value": number;
    "min-value-error-message": string;
    "allow-negative": boolean;
};

/**
 * Descrição do componente
 *
 * @prop {string} text - Propriedade texto
 *
 * @tag atlas-integer-input
 */
@customElement("atlas-integer-input")
export default class AtlasIntegerInput extends AtlasInput {
    @property({ type: Number, attribute: "max-value" }) maxValue: number;

    @property({ type: String, attribute: "max-value-error-message" }) maxValueErrorMessage: string;

    @property({ type: Number, attribute: "min-value" }) minValue: number;

    @property({ type: String, attribute: "min-value-error-message" }) minValueErrorMessage: string;

    @property({ type: Boolean, attribute: "allow-negative" }) allowNegative = false;

    private _maskInstance: Inputmask.Instance;

    connectedCallback() {
        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.buildMask();
        });
    }

    buildMask() {
        this.inputMode = "numeric";

        this._maskInstance = Inputmask({
            alias: "integer",
            allowMinus: this.allowNegative
        }).mask(this._input);

        // @ts-expect-error
        this._maskInstance.shadowRoot = this.shadowRoot;

        this.addValidator(new RangeValidator(this.maxValueErrorMessage, this.minValueErrorMessage));
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    onChangeValue() {
        if (!Inputmask.isValid(this.value, { alias: "integer" })) {
            this.value = Inputmask.format(this.value, { alias: "integer" });
        }

        super.onChangeValue();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-integer-input": AtlasIntegerInput;
    }
}
