import { LitElement, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import { getFormValues, reportFormValidity } from "@/helpers/form";
import { emit } from "@/internals/events";

import styles from "./atlas-form.scss";

/**
 * Formulário do atlas
 *
 * @prop {string} action - URL que será alvo do formulário
 * @prop {string} method - Método HTTP que será usado para o envio do formulário (get | post)
 * @prop {string} target - Onde será exibido o corpo da resposta do formulário
 *
 * @slot - Conteúdo do formulário, de onde serão buscados os valores
 *
 * @tag atlas-form
 */
@customElement("atlas-form")
export default class AtlasForm extends LitElement {
    static styles = styles;

    @property({ type: String }) action: string;

    @property({ type: String }) method: "get" | "post" = "get";

    @property({ type: String }) target: string;

    @query("form") associatedForm: HTMLFormElement;

    @state() private customAction = "";

    connectedCallback(): void {
        super.connectedCallback?.();
        this.handleFormData = this.handleFormData.bind(this);

        this.updateComplete.then(() => {
            this.associatedForm.addEventListener("formdata", this.handleFormData);
        });
    }

    disconnectedCallback(): void {
        this.associatedForm.removeEventListener("formdata", this.handleFormData);
    }

    getFormValues(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    submit(formAction?: string) {
        const isValid = reportFormValidity(this);
        this.customAction = "";

        if (isValid) {
            const submitEvent = emit(this, "atlas-form-submit");

            if (!submitEvent.defaultPrevented) {
                this.customAction = formAction || "";
                this.associatedForm.requestSubmit();
            }
        }
    }

    handleFormSubmit() {
        this.associatedForm.action = this.customAction || this.action;
    }

    handleFormData(event: FormDataEvent) {
        const formValues = this.getFormValues(true) as FormData;

        formValues.forEach((value, key) => {
            event.formData.append(key, value);
        });
    }

    render() {
        return html`
            <form method=${this.method} target=${this.target} @submit=${this.handleFormSubmit}></form>
            <slot></slot>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-form": AtlasForm;
    }
}
