import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { getProductInfo, Product } from "@/internals/product-info";
import { emit } from "@/internals/events";

import styles from "./atlas-product-card.scss";

/**
 * O Product Card é o item que aparece no dropdown de central de navegação, presente na navbar, ele serve renderizar as informações referente a um produto
 *
 * @prop {Product} product - Produto que será exibido
 * @prop {string} href - Link que será aberto ao clicar no card
 *
 * @tag atlas-product-card
 */
@customElement("atlas-product-card")
export default class AtlasProductCard extends LitElement {
    static styles = styles;

    @property({ type: String }) product: Product;

    @property({ type: String }) href: string;

    handleClick() {
        emit(this, "atlas-product-card-click");
    }

    render() {
        const productInfo = getProductInfo(this.product);

        return html`
            <a
                href=${this.href || productInfo.homePage}
                target="_blank"
                rel="noopener noreferrer"
                @click=${this.handleClick}
            >
                <div class="product-card">
                    <img class="product-logo" src=${productInfo.logoPresentation} alt=${productInfo.alt} />
                    <div class="product-info">
                        <h6 class="product-name">${productInfo.name}</h6>
                        <span class="product-description">${productInfo.description}</span>
                        <span class="product-link">Acesse o ${productInfo.name}</span>
                    </div>
                </div>
            </a>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-product-card": AtlasProductCard;
    }
}
