import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { TableColumn, TableSort } from "@/components/display/atlas-table/types";
import { emit } from "../../../internals/events";

import AtlasElement, { AtlasElementProps } from "../../../components/atlas-element";
import styles from "./atlas-table-header.scss";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/form/atlas-checkbox/atlas-checkbox";

export type TableHeaderProps = AtlasElementProps & {
    "columns": Array<TableColumn>;
    "has-actions": boolean;
    "selectable": boolean;
    "all-selected": boolean;
};

/**
 * @dependency atlas-text
 * @dependency atlas-icon
 * @dependency atlas-checkbox
 *
 * @prop {Array<TableColumn>} columns - Colunas que aparecem na table
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 * @prop {boolean} all-selected - Indica se todos os registros da table estão selecionados
 *
 * @event {CustomEvent} atlas-table-change-sorting - Evento disparado quando é clicado para alterar a ordenação de alguma coluna
 * @event {CustomEvent} atlas-table-select-all - Evento disparado quando é clicado no checkbox para selecionar todos os registros
 *
 * @tag atlas-table-header
 */
@customElement("atlas-table-header")
export default class AtlasTableHeader extends AtlasElement {
    static styles = styles;

    @property({ type: Array }) columns: Array<TableColumn> = [];

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean, attribute: "all-selected" }) allSelected = false;

    getSortingIcon(order: TableSort) {
        switch (order) {
            case "ascending":
                return "chevron-up";
            case "descending":
                return "chevron-down";
            default:
                return "up-and-down-arrows";
        }
    }

    getColumnNewOrder(currentOrder: TableSort) {
        let newOrder: TableSort = "none";

        if (!currentOrder || currentOrder === "none") {
            newOrder = "descending";
        } else if (currentOrder === "descending") {
            newOrder = "ascending";
        }

        return newOrder;
    }

    onClickSort(event: PointerEvent) {
        const target = event.target as HTMLElement;
        const { columnName, currentOrder } = target.dataset;
        const newOrder = this.getColumnNewOrder(currentOrder as TableSort);

        this.columns = this.columns.map((column) => ({
            ...column,
            order: column.name === columnName ? newOrder : "none"
        }));

        emit(this, "atlas-table-change-sorting", {
            detail: { column: columnName, order: newOrder }
        });
    }

    onClickSelectAll() {
        emit(this, "atlas-table-select-all");
    }

    renderSelectAll() {
        /* eslint-disable lit-a11y/click-events-have-key-events */
        return when(
            this.selectable,
            () => html`
                <div class="column-header is-selection" @click=${this.onClickSelectAll}>
                    <atlas-checkbox ?checked=${this.allSelected}></atlas-checkbox>
                </div>
            `
        );
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }

    renderColumns() {
        /* eslint-disable lit-a11y/click-events-have-key-events */
        return this.columns.map(
            (column) => html`
                <div
                    class=${classMap({ "column-header": true, "sortable": column.sortable })}
                    data-column-name=${column.name}
                    data-current-order=${column.order}
                    @click=${this.onClickSort}
                >
                    <atlas-text type="small" muted>${column.label}</atlas-text>
                    <atlas-icon name=${this.getSortingIcon(column.order)} size="2x" theme="secondary"></atlas-icon>
                </div>
            `
        );
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }

    renderActionsColumn() {
        return when(this.hasActions, () => html`<div class="column-header is-actions"></div>`);
    }

    render() {
        return html`
            <div class="atlas-table-header">
                ${this.renderSelectAll()}${this.renderColumns()} ${this.renderActionsColumn()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table-header": AtlasTableHeader;
    }
}
