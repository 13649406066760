import { LitElement, html } from "lit";
import { customElement, state, queryAssignedElements } from "lit/decorators.js";

import styles from "./atlas-critical-action-list.scss";

import type AtlasCriticalActionItem from "@/components/structure/atlas-critical-action-item/atlas-critical-action-item";

export type CriticalActionListProps = {};

/**
 * @tag atlas-critical-action-list
 */
@customElement("atlas-critical-action-list")
export default class AtlasCriticalActionList extends LitElement {
    static styles = styles;

    @queryAssignedElements({ selector: "atlas-critical-action-item", flatten: false })
    public _actionItems: Array<AtlasCriticalActionItem>;

    @state() private _checkedItemsCounter = 0;

    constructor() {
        super();

        this.updateCheckedItems = this.updateCheckedItems.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addEventListener("atlas-critical-action-item-check", this.updateCheckedItems);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-critical-action-item-check", this.updateCheckedItems);
    }

    updateCheckedItems(event: CustomEvent) {
        const { checked, group } = event.detail;

        if (group) return;

        if (checked) {
            this._checkedItemsCounter += 1;
        } else {
            this._checkedItemsCounter -= 1;
        }
    }

    getCheckedActionsCounter() {
        return this._checkedItemsCounter;
    }

    getCheckedActions() {
        const checkedItems: Array<AtlasCriticalActionItem> = this._actionItems.filter(
            (actionItem: AtlasCriticalActionItem) => actionItem.checked
        );

        const checkedSubItems: Array<AtlasCriticalActionItem> = [];

        checkedItems.forEach((actionItem: AtlasCriticalActionItem) => {
            if (!actionItem._hasSubItems) return;

            checkedSubItems.push(
                ...(actionItem._subItems.filter(
                    (actionSubItem: AtlasCriticalActionItem) => actionSubItem.checked
                ) as Array<AtlasCriticalActionItem>)
            );
        });

        checkedItems.push(...checkedSubItems);

        const actions: Array<Object> = [];

        checkedItems.forEach((actionItem: AtlasCriticalActionItem) => {
            if (actionItem._hasSubItems) return;

            actions.push({
                name: actionItem.name,
                value: actionItem.value
            });
        });

        return actions;
    }

    render() {
        return html`
            <div class="critical-action-list">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-critical-action-list": AtlasCriticalActionList;
    }
}
