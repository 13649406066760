import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import DeviceController from "../../../controllers/device-controller";

import AtlasElement, { AtlasElementProps } from "../../../components/atlas-element";
import styles from "./atlas-toolbar.scss";
import "../../display/atlas-dropdown-button/atlas-dropdown-button";

export type ToolbarProps = AtlasElementProps & {};

/**
 * @slot search - Slot onde poderá ser adicionado um input de pesquisa
 * @slot filter - Slot onde poderá ser adicionado um botão de filtro
 * @slot actions - Slot onde poderam ser adicionados ações na toolbar
 *
 * @tag atlas-toolbar
 */
@customElement("atlas-toolbar")
export default class AtlasToolbar extends AtlasElement {
    static styles = styles;

    private _deviceController = new DeviceController(this);

    renderActions() {
        return when(
            this._deviceController.isMobile,
            () => html`
                <atlas-dropdown-button description="Ações" block>
                    <slot name="actions"></slot>
                </atlas-dropdown-button>
            `,
            () => html`<slot name="actions"></slot>`
        );
    }

    render() {
        return html`
            <div class="atlas-toolbar">
                <div class="search-and-filter">
                    <slot name="search"></slot>
                    <slot name="filter"></slot>
                </div>
                <div class="actions-wrapper">${this.renderActions()}</div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-toolbar": AtlasToolbar;
    }
}
