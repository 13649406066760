import emojiRegex from "emoji-regex";

import FormElement from "@/components/form/form-element";
import FieldValidator from "./field-validator";

export default class EmojiValidator implements FieldValidator {
    name = "emoji";

    reportOnChange = true;

    emojiErrorMessage: string;

    constructor(emojiErrorMessage?: string) {
        this.emojiErrorMessage = emojiErrorMessage;
    }

    getInvalidMessage(): string {
        return this.emojiErrorMessage || "Não é permitido emoji neste campo";
    }

    validate(element: FormElement): boolean {
        const regex = emojiRegex();
        const elementValue = element.getElementValue();

        if (regex.test(elementValue)) {
            return false;
        }

        return true;
    }
}
