import { html } from "lit";
import { customElement, property, queryAssignedElements, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";

import { getFormValues } from "@/helpers/form";
import { emit } from "@/internals/events";

import DeviceController from "@/controllers/device-controller";

import AtlasFilterGroup from "@/components/form/atlas-filter-group/atlas-filter-group";
import type { AtlasButton } from "@/components/display/atlas-button/atlas-button";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-filter-form.scss";
import "@/components/display/atlas-button/atlas-button";

export type FilterFormProps = AtlasElementProps & {
    type: "simple" | "advanced";
};

/**
 * @dependency atlas-button
 *
 * @prop {string} type - Define o tipo do formulário, pode ser "simple" ou "advanced"
 *
 * @tag atlas-filter-form
 */
@customElement("atlas-filter-form")
export default class AtlasFilterForm extends AtlasElement {
    static styles = styles;

    @property({ type: String }) type: "simple" | "advanced";

    @queryAssignedElements({ selector: "atlas-filter-group", flatten: true })
    private _filterGroups!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-1", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol1!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-2", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol2!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-3", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol3!: Array<AtlasFilterGroup>;

    @queryAssignedElements({ slot: "col-4", selector: "atlas-filter-group", flatten: true })
    private _filterGroupsCol4!: Array<AtlasFilterGroup>;

    @state() hasAdvancedFilter = false;

    private _deviceController = new DeviceController(this);

    async onChangeColSlots() {
        await this.updateComplete;

        (this.shadowRoot.querySelector(".auto-col") as HTMLElement).style.display = "none";
        this.shadowRoot.querySelectorAll(".col").forEach((col: HTMLElement) => {
            /* eslint-disable no-param-reassign */
            col.style.display = "flex";
            /* eslint-enable no-param-reassign */
        });

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countCheckedFields();
    }

    async onChangeMainSlot() {
        await this.updateComplete;

        this.shadowRoot.querySelectorAll(".col").forEach((col: HTMLElement) => {
            /* eslint-disable no-param-reassign */
            col.style.display = "none";
            /* eslint-enable no-param-reassign */
        });

        if (this.type === "advanced" || (this.type === "simple" && this._deviceController.isMobile)) {
            this.setCollapsibleGroups();
        }

        this.countCheckedFields();
    }

    countCheckedFields() {
        const filterGroups = this.getFilterGroups();

        const counter = filterGroups.reduce(
            (acc: number, filter: AtlasFilterGroup) => acc + filter.countCheckedFields(),
            0
        );

        emit(this, "atlas-filter-form-count", {
            detail: {
                counter
            }
        });
    }

    setCollapsibleGroups() {
        const filterGroups = this.getFilterGroups();

        /* eslint-disable no-param-reassign */
        filterGroups.forEach((filterGroup: AtlasFilterGroup) => {
            if (filterGroup.header) {
                filterGroup.collapsible = true;
            }
        });
        /* eslint-enable no-param-reassign */
    }

    openAdvancedFilter() {
        emit(this, "atlas-open-advanced-filter", {
            detail: { ...this.getFilterData(false) }
        });
    }

    cleanFilter(event: Event) {
        const { target } = event;

        (target as AtlasButton).loading = true;
        this.disableButtons();

        emit(this, "atlas-clean-filter", {
            detail: { ...this.getFilterData(false) }
        });

        emit(this, "atlas-filter-close");
    }

    applyFilter(event: Event) {
        const { target } = event;

        (target as AtlasButton).loading = true;
        this.disableButtons();

        emit(this, "atlas-apply-filter", {
            detail: { ...this.getFilterData(false) }
        });

        emit(this, "atlas-filter-close");
    }

    disableButtons() {
        const atlasButtons = this.shadowRoot.querySelectorAll("atlas-button");

        atlasButtons.forEach((button) => {
            button.disable();
        });
    }

    enableButtons() {
        const atlasButtons = this.shadowRoot.querySelectorAll("atlas-button");

        atlasButtons.forEach((button) => {
            /* eslint-disable no-param-reassign */
            button.loading = false;
            button.enable();
            /* eslint-enable no-param-reassign */
        });
    }

    getFilterData(asFormData?: boolean) {
        return getFormValues(this, asFormData);
    }

    mergeFilterData(filterData: any) {
        const filterGroups = this.getFilterGroups();

        filterGroups.forEach((filter: AtlasFilterGroup) => filter.resetFilterElements());

        Object.entries(filterData).forEach(([name, values]: [string, string | Array<string>]) => {
            const filterGroup = filterGroups.find((filter: AtlasFilterGroup) => filter.name === name);

            if (!filterGroup) return;

            filterGroup.setFilterElementsValues(typeof values === "string" ? [values] : values);
        });
    }

    getFilterGroups() {
        return [
            ...this._filterGroups,
            ...this._filterGroupsCol1,
            ...this._filterGroupsCol2,
            ...this._filterGroupsCol3,
            ...this._filterGroupsCol4
        ];
    }

    renderFooter() {
        const advancedFilterButton = when(
            this.type === "simple" && this.hasAdvancedFilter,
            () => html`
                <atlas-button
                    description="Filtros avançados"
                    theme="secondary"
                    type="outlined"
                    @atlas-button-click=${this.openAdvancedFilter}
                ></atlas-button>
            `
        );

        return html`
            <div class="atlas-filter-form-footer">
                <div>${advancedFilterButton}</div>
                <div class="actions-buttons">
                    <atlas-button
                        description="Limpar"
                        theme="secondary"
                        @atlas-button-click=${this.cleanFilter}
                    ></atlas-button>
                    <atlas-button description="Aplicar" @atlas-button-click=${this.applyFilter}></atlas-button>
                </div>
            </div>
        `;
    }

    render() {
        const filterClass = {
            "atlas-filter-form": true,
            "atlas-advanced-filter-form": this.type === "advanced" || this._deviceController.isMobile
        };

        return html`
            <div class="${classMap(filterClass)}">
                <div class="atlas-filter-form-content">
                    <div class="auto-col">
                        <slot @slotchange=${this.onChangeMainSlot}></slot>
                    </div>

                    <div class="col">
                        <slot name="col-1" @slotchange=${this.onChangeColSlots}> </slot>
                    </div>

                    <div class="col">
                        <slot name="col-2" @slotchange=${this.onChangeColSlots}> </slot>
                    </div>

                    <div class="col">
                        <slot name="col-3" @slotchange=${this.onChangeColSlots}> </slot>
                    </div>

                    <div class="col">
                        <slot name="col-4" @slotchange=${this.onChangeColSlots}> </slot>
                    </div>
                </div>
                ${this.renderFooter()}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-filter-form": AtlasFilterForm;
    }
}
