import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import styles from "./atlas-desktop-dropdown.scss";

export type DesktopDropdownProps = {
    "header": string;
    "width": number;
    "open": boolean;
    "max-width": string;
    "max-height": number;
    "spaced-content": boolean;
    "no-gap": boolean;
    "overflow": boolean;
};

/**
 * @prop {string} header - Título do dropdown.
 * @prop {number} width - Largura do dropdown. (Caso não seja passada, é definida baseada no tamanho do seu conteúdo, respeitando o mínimo de `160` e o máximo de `540`)
 * @prop {boolean} open - Booleano que indica se o dropdown está aberto.
 * @prop {string} max-width - Largura máxima do dropdown.
 * @prop {number} max-height - Altura máxima do dropdown.
 * @prop {boolean} spaced-content - Indica se o conteúdo deve possui um espaçamento interno, alinhando com o título.
 * @prop {boolean} no-gap - Indica se os conteúdos do dropdown não devem possuir espaçamento entre eles, ex: lista de itens.
 * @prop {boolean} overflow - Coloca o overflow como visible para casos que precisamos de algo que extrapole o container
 *
 * @slot - Slot padrão usado para definir o conteúdo do dropdown
 *
 * @tag atlas-desktop-dropdown
 */
@customElement("atlas-desktop-dropdown")
export default class AtlasDesktopDropdown extends LitElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: Number }) width: number;

    @property({ type: Boolean }) open: boolean;

    @property({ type: Boolean }) overflow: boolean;

    @property({ type: String, attribute: "max-width" }) maxWidth: string;

    @property({ type: Number, attribute: "max-height" }) maxHeight: number;

    @property({ type: Boolean, attribute: "spaced-content" }) spacedContent: boolean;

    @property({ type: Boolean, attribute: "no-gap" }) noGap: boolean;

    @property({ type: Boolean, attribute: "scale-vertically" }) scaleVertically: boolean;

    renderHeader() {
        return when(
            this.header && this.header.trim(),
            () => html`
                <div class="dropdown-title">
                    <atlas-heading ellipsis type="h5" title=${this.header}>${this.header}</atlas-heading>
                    <slot name="subheading"></slot>
                </div>
            `,
            () => null
        );
    }

    render() {
        const dropdownClass = {
            "dropdown": true,
            "fade-and-scale": true,
            "scale-vertically": this.scaleVertically,
            "show": this.open,
            "spaced-content": this.spacedContent,
            "no-gap": this.noGap,
            "overflow": this.overflow,
            "has-width": !!this.width
        };

        const maxWidth = this.maxWidth === "unset" ? "unset" : "540px";
        const maxHeight = this.maxHeight > 180 ? `${this.maxHeight}px` : "none";
        const width = this.width > 0 ? `${this.width}px` : "auto";

        return html`
            <div
                class=${classMap(dropdownClass)}
                style="max-height: ${maxHeight}; width: ${width}; max-width: ${maxWidth}"
            >
                ${this.renderHeader()}
                <div class="dropdown-content">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-desktop-dropdown": AtlasDesktopDropdown;
    }
}
