import asaasLogo from "@/assets/images/logos/asaas-logo.svg";
import asaasSmallLogo from "@/assets/images/logos/asaas-small-logo.svg";
import baseLogo from "@/assets/images/logos/base-logo.svg";
import baseSmallLogo from "@/assets/images/logos/base-small-logo.svg";
import moneyLogo from "@/assets/images/logos/money-logo.svg";
import moneyLogoAlt from "@/assets/images/logos/money-logo-alt.svg";
import moneySmallLogo from "@/assets/images/logos/money-small-logo.svg";

const PRODUCT_MAPPING = {
    asaas: {
        name: "Asaas",
        description: "Simplifique o recebimento de cobranças e automatize seu negócio.",
        alt: "Logomarca Asaas",
        logoDefault: asaasLogo,
        logoMini: asaasSmallLogo,
        logoPresentation: asaasLogo,
        homePage: "https://www.asaas.com/"
    },
    base: {
        name: "BASE by Asaas",
        description: "O sistema de gestão financeira e fiscal ideial para a sua empresa.",
        alt: "Logomarca BASE",
        logoDefault: baseLogo,
        logoMini: baseSmallLogo,
        logoPresentation: baseSmallLogo,
        homePage: "https://apps.baseerp.com.br"
    },
    money: {
        name: "Asaas Money",
        description: "O seu aplicativo de pagamentos. Agilidade na hora de pagar e receber.",
        alt: "Logomarca Asaas money",
        logoDefault: moneyLogo,
        logoMini: moneySmallLogo,
        logoPresentation: moneyLogoAlt,
        homePage: "https://asaasmoney.com.br/"
    }
};

export type Product = "asaas" | "base" | "money";

export type ProductInfo = {
    name: string;
    description: string;
    alt: string;
    logoDefault: string;
    logoMini: string;
    logoPresentation: string;
    homePage: string;
};

export function getProductInfo(product: Product): ProductInfo {
    return PRODUCT_MAPPING[product] as ProductInfo;
}

export function getHomePage(product: Product): string {
    return getProductInfo(product).homePage;
}

export default {
    getProductInfo,
    getHomePage
};
