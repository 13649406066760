import { html } from "lit";
import { property, state } from "lit/decorators.js";

import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";

import FieldValidator from "@/internals/validators/field-validator";
import RequiredValidator from "@/internals/validators/required-validator";

import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";

export type FormElementProps = AtlasElementProps & {
    id: string;
    name: string;
    value: string;
    disabled: boolean;
    required: boolean;
};

/**
 * Classe base para definir elementos de um formulário
 *
 * @prop {string} name - O nome do elemento
 * @prop {string} value - O valor do elemento
 * @prop {string} required-error-message - Mensagem que é exibida quando o campo é requerido mas não foi preenchido
 * @prop {boolean} disabled - Indica se o elemento está desabilitado
 * @prop {boolean} required - Indica se o elemento é obrigatório
 *
 */
export default class FormElement extends AtlasElement {
    @property({ type: String }) name = "";

    @property({ type: String }) value = "";

    @property({ type: String, attribute: "required-error-message" }) requiredErrorMessage = "";

    @property({ type: Boolean, reflect: true }) required = false;

    @property({ type: Boolean, reflect: true }) disabled = false;

    @property({ type: Boolean, attribute: "ignore-validations" }) ignoreValidations = false;

    @state() _showValidationState = false;

    @state() _valid = true;

    @state() _validationMessage = "";

    private _validators: FieldValidator[] = [];

    connectedCallback(): void {
        super.connectedCallback?.();

        this.addValidator(new RequiredValidator(this.requiredErrorMessage));

        this.updateComplete.then(() => {
            this.checkValidity();
        });
    }

    checkValidity(): boolean {
        if (this.ignoreValidations) return true;

        this._valid = true;

        if (!this.disabled) {
            for (const validator of this._validators) {
                const isValid = validator.validate(this);

                if (!isValid) {
                    this._valid = false;
                    this._validationMessage = validator.getInvalidMessage();

                    if (validator.reportOnChange) {
                        this._showValidationState = true;
                    }

                    break;
                }
            }
        }

        return this._valid;
    }

    reportValidity(): boolean {
        if (this.ignoreValidations) return true;

        this.checkValidity();
        this._showValidationState = !this._valid;

        return this._valid;
    }

    setErrorMessage(message: string) {
        this._validationMessage = message;
        this.setValidationState(false);
    }

    setValidationState(validationState: boolean) {
        this._valid = validationState;
        this._showValidationState = !validationState;
    }

    addValidator(validator: FieldValidator): void {
        this._validators.push(validator);
    }

    getValidator(name: string) {
        return this._validators.find((validator) => validator.name === name);
    }

    removeValidator(name: string): void {
        this._validators = this._validators.filter((validator) => validator.name !== name);
    }

    enable(): void {
        this.disabled = false;
    }

    disable(): void {
        this.disabled = true;
    }

    toggleDisabled(disabled?: boolean): void {
        if (typeof disabled === "boolean") {
            this.disabled = !disabled;
        } else {
            this.disabled = !this.disabled;
        }
    }

    getElementValue(): any {
        return this.value;
    }

    @Watch("value", true)
    onChange() {
        this.checkValidity();
    }

    @Watch("disabled", true)
    onChangeDisabled() {
        if (this.disabled) this.setValidationState(true);

        emit(this, "atlas-form-element-disabled-change");
    }

    @Watch("required", true)
    onChangeRequired() {
        emit(this, "atlas-form-element-required-change");
    }

    renderValidationMessage() {
        return when(
            this._showValidationState && this._validationMessage !== "",
            () => html`<div class="invalid-feedback">${this._validationMessage}</div>`
        );
    }
}
