import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";

import { DisplayType } from "./types";

import styles from "./atlas-display.scss";

/**
 * Componente de tipografia relacionado aos tipos display que existem
 *
 * @prop {string} type - O tipo de display desejado, dentre os tipos possiveis
 *
 * @tag atlas-display
 */
@customElement("atlas-display")
export default class AtlasDisplay extends LitElement {
    static styles = styles;

    @property({ type: String }) type: DisplayType;

    render() {
        return html`
            <div class="${this.type}">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-display": AtlasDisplay;
    }
}
