import { LitElement, html } from "lit";
import { customElement, property, queryAssignedElements } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import { AtlasButton } from "@/components/display/atlas-button/atlas-button";

import styles from "./atlas-empty-state.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type EmptyStateProps = {
    icon: string;
    header: string;
    description: string;
    small: boolean;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {string} icon - Ícone exibido no empty-state
 * @prop {string} header - Título do empty-state
 * @prop {string} description - Descrição do empty-state
 * @prop {boolean} small - Indica se deve exibir a variação pequena do empty-state
 *
 * @tag atlas-empty-state
 */
@customElement("atlas-empty-state")
export default class AtlasEmptyState extends LitElement {
    static styles = styles;

    @property({ type: String }) icon: string;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: Boolean }) small: boolean;

    @queryAssignedElements({ selector: "atlas-button", slot: "button" })
    protected buttonsElements!: Array<AtlasButton>;

    private deviceController = new DeviceController(this, this.onChangeScreenType.bind(this));

    renderHeader() {
        return when(
            !!this.header,
            () => html`<atlas-heading type=${this.small ? "h6" : "h5"} muted>${this.header}</atlas-heading>`
        );
    }

    renderMessage() {
        return when(
            !!this.description,
            () => html`<atlas-text type=${this.small ? "small" : "paragraph"} muted>${this.description}</atlas-text>`
        );
    }

    onChangeScreenType() {
        this.setButtonsSize();
    }

    setButtonsSize() {
        this.buttonsElements.forEach((button) => {
            if (this.deviceController.isMobile) {
                button.setAttribute("size", "lg");
                return;
            }

            button.setAttribute("size", this.small ? "sm" : "md");
        });
    }

    render() {
        const emptyStateClass = {
            "empty-state": true,
            "small": this.small
        };

        const iconSize = this.small ? "5x" : "6x";

        return html`
            <div class=${classMap(emptyStateClass)}>
                <atlas-icon name=${this.icon} theme="primary" size=${iconSize}></atlas-icon>
                ${this.renderHeader()} ${this.renderMessage()}
                <slot name="button" @slotchange=${this.setButtonsSize}></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-empty-state": AtlasEmptyState;
    }
}
