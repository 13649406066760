import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import styles from "./atlas-grid.scss";

export type GridProps = {
    container: boolean;
};

/**
 * @prop {boolean} container - Indica se a grid vai ter o comportamento de um container (Centralizando o conteúdo na página)
 *
 * @slot - Usado para incluir os componentes atlas-row
 */
@customElement("atlas-grid")
class AtlasGrid extends LitElement {
    static styles = styles;

    @property({ type: Boolean }) container = false;

    render() {
        const containerClasses = {
            "atlas-grid": true,
            "container": this.container
        };

        return html`
            <div class="${classMap(containerClasses)}">
                <slot></slot>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-grid": AtlasGrid;
    }
}
