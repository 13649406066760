import "@/styles/main.scss";
import "@/internals/prototypes";

import "@/components/dashboard/atlas-bar-chart-item/atlas-bar-chart-item";
import "@/components/dashboard/atlas-calendar/atlas-calendar";
import "@/components/dashboard/atlas-calendar-timeline/atlas-calendar-timeline";
import "@/components/dashboard/atlas-calendar-timeline-item/atlas-calendar-timeline-item";
import "@/components/dashboard/atlas-chart/atlas-chart";
import "@/components/dashboard/atlas-onboarding/atlas-onboarding";
import "@/components/dashboard/atlas-onboarding-step/atlas-onboarding-step";
import "@/components/display/atlas-alert/atlas-alert";
import "@/components/display/atlas-avatar/atlas-avatar";
import "@/components/display/atlas-badge/atlas-badge";
import "@/components/display/atlas-bottom-sheet/atlas-bottom-sheet";
import "@/components/display/atlas-breadcrumb/atlas-breadcrumb";
import "@/components/display/atlas-breadcrumb-item/atlas-breadcrumb-item";
import "@/components/display/atlas-button/atlas-button";
import "@/components/display/atlas-card/atlas-card";
import "@/components/display/atlas-card-button/atlas-card-button";
import "@/components/display/atlas-collapse-button/atlas-collapse-button";
import "@/components/display/atlas-desktop-dropdown/atlas-desktop-dropdown";
import "@/components/display/atlas-display/atlas-display";
import "@/components/display/atlas-dropdown/atlas-dropdown";
import "@/components/display/atlas-dropdown-button/atlas-dropdown-button";
import "@/components/display/atlas-dropdown-item/atlas-dropdown-item";
import "@/components/display/atlas-empty-state/atlas-empty-state";
import "@/components/display/atlas-fixed-alert/atlas-fixed-alert";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-icon-button/atlas-icon-button";
import "@/components/display/atlas-illustration/atlas-illustration";
import "@/components/display/atlas-link/atlas-link";
import "@/components/display/atlas-loading/atlas-loading";
import "@/components/display/atlas-navbar-dropdown/atlas-navbar-dropdown";
import "@/components/display/atlas-notification-card/atlas-notification-card";
import "@/components/display/atlas-offcanvas/atlas-offcanvas";
import "@/components/display/atlas-popover/atlas-popover";
import "@/components/display/atlas-product-card/atlas-product-card";
import "@/components/display/atlas-table/atlas-table";
import "@/components/display/atlas-table-header/atlas-table-header";
import "@/components/display/atlas-tag/atlas-tag";
import "@/components/display/atlas-text/atlas-text";
import "@/components/display/atlas-tooltip/atlas-tooltip";
import "@/components/form/atlas-checkbox/atlas-checkbox";
import "@/components/form/atlas-datepicker/atlas-datepicker";
import "@/components/form/atlas-dropzone/atlas-dropzone";
import "@/components/form/atlas-filter/atlas-filter";
import "@/components/form/atlas-filter-form/atlas-filter-form";
import "@/components/form/atlas-filter-group/atlas-filter-group";
import "@/components/form/atlas-float-input/atlas-float-input";
import "@/components/form/atlas-form/atlas-form";
import "@/components/form/atlas-helper-text/atlas-helper-text";
import "@/components/form/atlas-input/atlas-input";
import "@/components/form/atlas-integer-input/atlas-integer-input";
import "@/components/form/atlas-masked-input/atlas-masked-input";
import "@/components/form/atlas-money/atlas-money";
import "@/components/form/atlas-option/atlas-option";
import "@/components/form/atlas-password-input/atlas-password-input";
import "@/components/form/atlas-percentage/atlas-percentage";
import "@/components/form/atlas-postal-code/atlas-postal-code";
import "@/components/form/atlas-radio/atlas-radio";
import "@/components/form/atlas-search-input/atlas-search-input";
import "@/components/form/atlas-select/atlas-select";
import "@/components/form/atlas-select-item/atlas-select-item";
import "@/components/form/atlas-selection-card/atlas-selection-card";
import "@/components/form/atlas-switch/atlas-switch";
import "@/components/form/atlas-textarea/atlas-textarea";
import "@/components/form/atlas-toggle/atlas-toggle";
import "@/components/form/atlas-toggle-item/atlas-toggle-item";
import "@/components/layout/atlas-col/atlas-col";
import "@/components/layout/atlas-collapse/atlas-collapse";
import "@/components/layout/atlas-divider/atlas-divider";
import "@/components/layout/atlas-element-group/atlas-element-group";
import "@/components/layout/atlas-grid/atlas-grid";
import "@/components/layout/atlas-layout/atlas-layout";
import "@/components/layout/atlas-list/atlas-list";
import "@/components/layout/atlas-list-item/atlas-list-item";
import "@/components/layout/atlas-page/atlas-page";
import "@/components/layout/atlas-page-content/atlas-page-content";
import "@/components/layout/atlas-page-header/atlas-page-header";
import "@/components/layout/atlas-panel/atlas-panel";
import "@/components/layout/atlas-row/atlas-row";
import "@/components/layout/atlas-section/atlas-section";
import "@/components/layout/atlas-section-group/atlas-section-group";
import "@/components/layout/atlas-toolbar/atlas-toolbar";
import "@/components/structure/atlas-critical-action/atlas-critical-action";
import "@/components/structure/atlas-critical-action-item/atlas-critical-action-item";
import "@/components/structure/atlas-critical-action-list/atlas-critical-action-list";
import "@/components/structure/atlas-navbar/atlas-navbar";
import "@/components/structure/atlas-screen/atlas-screen";
import "@/components/structure/atlas-sidebar/atlas-sidebar";
import "@/components/structure/atlas-sidebar-menu/atlas-sidebar-menu";
import "@/components/structure/atlas-sidebar-menu-item/atlas-sidebar-menu-item";
import "@/components/wizard/atlas-stepper/atlas-stepper";
import "@/components/wizard/atlas-summary-card/atlas-summary-card";
import "@/components/wizard/atlas-summary-container/atlas-summary-container";
import "@/components/wizard/atlas-summary-item/atlas-summary-item";
import "@/components/wizard/atlas-summary-table/atlas-summary-table";
import "@/components/wizard/atlas-summary-table-col/atlas-summary-table-col";
import "@/components/wizard/atlas-summary-table-row/atlas-summary-table-row";
import "@/components/wizard/atlas-wizard/atlas-wizard";
import "@/components/wizard/atlas-wizard-finish-step/atlas-wizard-finish-step";
import "@/components/wizard/atlas-wizard-intro-step/atlas-wizard-intro-step";
import "@/components/wizard/atlas-wizard-step/atlas-wizard-step";

export * as browser from "@/helpers/browser";
export * as cpfCnpj from "@/helpers/cpf-cnpj";
export * as device from "@/helpers/device";
export * as email from "@/helpers/email";
export * as form from "@/helpers/form";
export * as formatters from "@/helpers/formatters";
export * as loading from "@/helpers/loading";
export * as mfa from "@/helpers/mfa";
export * as notifications from "@/helpers/notifications";
export * as phoneNumber from "@/helpers/phone-number";
export * as postalCode from "@/helpers/postal-code";
export * as request from "@/helpers/request";

document.addEventListener("DOMContentLoaded", () => {
    document.body.classList.add("has-atlas");
});
