import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { filterEmptyNodes } from "@/internals/elements";

import AtlasElement from "@/components/atlas-element";
import styles from "./atlas-fixed-alert.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-text/atlas-text";

export type FixedAlertProps = {
    header: string;
    type: "success" | "error" | "warning" | "info";
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 * @dependency atlas-text
 *
 * @prop {string} header - Título do alert
 * @prop {"success" | "error" | "warning" | "info"} type - Tipo do alert
 *
 * @tag atlas-fixed-alert
 */
@customElement("atlas-fixed-alert")
export default class AtlasFixedAlert extends AtlasElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) type: "success" | "error" | "warning" | "info" = "info";

    @state() private _hasSlottedContent = false;

    getAlertIcon() {
        return {
            success: "check",
            error: "alert-triangle",
            warning: "alert-triangle",
            info: "info"
        }[this.type];
    }

    getAlertTheme() {
        return {
            success: "success",
            error: "danger",
            warning: "warning",
            info: "primary"
        }[this.type];
    }

    onChangeSlottedContent() {
        const slottedNodes = this.shadowRoot.querySelector("slot").assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(slottedNodes);

        this._hasSlottedContent = filteredNodes.length > 0;
    }

    renderSkeleton() {
        return html`
            <div class="fixed-alert skeleton">
                <atlas-icon size="3x"></atlas-icon>
                <div class="alert-content skeleton">
                    <atlas-heading type="h5"></atlas-heading>
                    <atlas-text></atlas-text>
                </div>
            </div>
        `;
    }

    renderElement() {
        const alertClass = {
            "fixed-alert": true,
            [`alert-${this.type}`]: true,
            "has-content": this._hasSlottedContent
        };

        return html`
            <div class=${classMap(alertClass)}>
                <atlas-icon name=${this.getAlertIcon()} size="3x"></atlas-icon>
                <div class="alert-content">
                    <atlas-heading type="h5">${this.header}</atlas-heading>
                    <atlas-text theme=${this.getAlertTheme()} theme-variation="800">
                        <slot @slotchange=${this.onChangeSlottedContent}></slot>
                    </atlas-text>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-fixed-alert": AtlasFixedAlert;
    }
}
