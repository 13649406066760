import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { when } from "lit/directives/when.js";

import { emit } from "@/internals/events";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-calendar-timeline.scss";

import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-icon/atlas-icon";

export type CalendarTimelineProps = AtlasElementProps & {
    "header": string;
    "icon": string;
    "button-text": string;
    "button-link": string;
    "button-disabled": boolean;
    "atlas-timeline-button-click": Function;
};

/**
 * @dependency atlas-heading
 * @dependency atlas-icon
 *
 * @prop {string} header - Título da timeline
 * @prop {string} icon - Ícone que aparece ao lado do título
 * @prop {string} button-text - Texto do botão que aparece no direito do título
 * @prop {string} button-link - Link para qual o usuário será redirecionado ao clicar no botão
 * @prop {boolean} button-disabled - Indica se o botão está desabilitado
 *
 * @event {CustomEvent} atlas-timeline-button-click - Evento disparado quando é feito o clique no botão
 *
 * @tag atlas-calendar-timeline
 */
@customElement("atlas-calendar-timeline")
export default class AtlasCalendarTimeline extends AtlasElement {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) icon: string;

    @property({ type: String, attribute: "button-text" }) buttonText: string;

    @property({ type: String, attribute: "button-link" }) buttonLink: string;

    @property({ type: Boolean, attribute: "button-disabled" }) buttonDisabled: boolean;

    handleButtonClick() {
        if (this.buttonLink) {
            window.open(this.buttonLink, "_self");
        }

        emit(this, "atlas-timeline-button-click");
    }

    renderButton() {
        return when(
            !!this.buttonText,
            () => html`
                <button class="timeline-button" ?disabled=${this.buttonDisabled} @click=${this.handleButtonClick}>
                    <span>${this.buttonText}</span>
                    <atlas-icon name="chevron-right" theme="primary" size="3x"></atlas-icon>
                </button>
            `
        );
    }

    renderElement() {
        return html`
            <div class="timeline">
                <div class="timeline-header">
                    <div class="timeline-title">
                        <atlas-icon name=${this.icon} theme="primary" size="3x"></atlas-icon>
                        <atlas-heading type="h6" theme="primary" reset-line-height>${this.header}</atlas-heading>
                    </div>
                    ${this.renderButton()}
                </div>
                <div class="timeline-body">
                    <slot></slot>
                </div>
            </div>
        `;
    }

    renderSkeleton() {
        return html`
            <div class="timeline">
                <div class="timeline-header" style="margin-bottom: 16px">
                    <div class="skeleton" style="width: 86px">
                        <atlas-text></atlas-text>
                    </div>
                    <div class="skeleton" style="width: 56px">
                        <atlas-text></atlas-text>
                    </div>
                </div>
                <div class="timeline-body">
                    <slot></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-calendar-timeline": AtlasCalendarTimeline;
    }
}
