import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { Watch } from "@/decorators/watch";

import type AtlasTableHeader from "@/components/display/atlas-table-header/atlas-table-header";
import AtlasElement, { AtlasElementProps } from "@/components/atlas-element";
import styles from "./atlas-table.scss";

export type TableProps = AtlasElementProps & {
    "has-actions": boolean;
    "selectable": boolean;
};

/**
 * @prop {boolean} has-actions - Indica se a table tem ações
 * @prop {boolean} selectable - Indica se a table permite múltipla seleção
 *
 * @slot header - Slot para colocar o cabeçalho da table
 * @slot body - Slot para colocar o corpo da table
 * @slot footer - Slot para colocar o rodapé da table
 *
 * @tag atlas-table
 */
@customElement("atlas-table")
export default class AtlasTable extends AtlasElement {
    static styles = styles;

    @property({ type: Boolean }) selectable = false;

    @property({ type: Boolean, attribute: "has-actions" }) hasActions = false;

    @state() private _hasFooter = false;

    getSlottedHeader(): AtlasTableHeader {
        const headerSlot = this.shadowRoot.querySelector("slot[name=header]") as HTMLSlotElement;
        const slottedElements = headerSlot.assignedElements();

        return (slottedElements[0] as AtlasTableHeader) || null;
    }

    onSlotChangeFooter() {
        const footerSlot = this.shadowRoot.querySelector("slot[name=footer]") as HTMLSlotElement;
        const footerContent = footerSlot.assignedElements();

        this._hasFooter = footerContent.length > 0;
    }

    @Watch(["selectable", "hasActions"])
    async applyPropsInHeader() {
        await this.updateComplete;

        this.getSlottedHeader()?.toggleAttribute("selectable", this.selectable);
        this.getSlottedHeader()?.toggleAttribute("has-actions", this.hasActions);
    }

    render() {
        const tableClass = {
            "atlas-table": true,
            "has-footer": this._hasFooter
        };

        return html`
            <div class=${classMap(tableClass)}>
                <div class="table-header">
                    <slot name="header"></slot>
                </div>
                <div class="table-body">
                    <slot name="body"></slot>
                </div>
                <div class="table-footer">
                    <slot name="footer" @slotchange=${this.onSlotChangeFooter}></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-table": AtlasTable;
    }
}
