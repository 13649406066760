import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { map } from "lit/directives/map.js";
import { range } from "lit/directives/range.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";

import DeviceController from "@/controllers/device-controller";

import { WithCollapseMixin } from "@/internals/mixins/with-collapse-mixin";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import styles from "./atlas-panel.scss";
import "@/components/display/atlas-heading/atlas-heading";
import "@/components/display/atlas-text/atlas-text";
import "@/components/layout/atlas-collapse/atlas-collapse";

export type PanelProps = AtlasElementProps & {
    "header": string;
    "description": string;
    "collapsible": boolean;
    "carousel": boolean;
    "items-per-page": number;
};

/**
 * @prop {string} header - Título do painel
 * @prop {string} description - Descrição do painel
 * @prop {boolean} collapsible - Indica se o painel vai ser expansível/colapsável
 * @prop {boolean} carousel - Indica se o painel vai ser exibido como um carosel
 * @prop {number} items-per-page - Número de itens que vão aparecer em uma página do carrossel
 *
 * @tag atlas-panel
 */
@customElement("atlas-panel")
export default class AtlasPanel extends WithCollapseMixin(AtlasElement) {
    static styles = styles;

    @property({ type: String }) header: string;

    @property({ type: String }) description: string;

    @property({ type: Boolean }) carousel: boolean;

    @property({ type: Number, attribute: "items-per-page" }) itemsPerPage: number = 1;

    @state() private _currentPage = 0;

    @state() private _countItems = 0;

    @state() private _countPages = 0;

    @state() private _autoPlayId: any;

    @state() private _autoPlayPaused: boolean;

    @state() private _hasSlottedActions = false;

    private _deviceController = new DeviceController(this, this.onScreenModeChange.bind(this));

    connectedCallback() {
        super.connectedCallback?.();

        this.addEventListener("mouseover", this.pauseAutoPlay);
        this.addEventListener("mouseout", this.resumeAutoPlay);

        if (this.collapsible) {
            this._collapsed = true;
        }

        this.updateComplete.then(() => {
            this.registerCarouselAutoPlay();
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        this.cancelCarouselAutoPlay();
        this.removeEventListener("mouseover", this.pauseAutoPlay);
        this.removeEventListener("mouseout", this.resumeAutoPlay);
    }

    registerCarouselAutoPlay() {
        if (!this._deviceController.isMobile && this.carousel) {
            this._autoPlayId = setInterval(() => {
                if (!this._autoPlayPaused) {
                    this.onClickNextPage();
                }
            }, 5000);
        }
    }

    cancelCarouselAutoPlay() {
        if (this._autoPlayId) {
            clearInterval(this._autoPlayId);
        }
    }

    pauseAutoPlay() {
        this._autoPlayPaused = true;
    }

    resumeAutoPlay() {
        this._autoPlayPaused = false;
    }

    getSlottedItems() {
        return (this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement)?.assignedElements() || [];
    }

    getPanelPaddingSize() {
        return this._deviceController.isMobile ? 16 : 24;
    }

    getItemsPerPage() {
        return this._deviceController.isMobile || this.itemsPerPage < 1 ? 1 : this.itemsPerPage;
    }

    updatePagesCount() {
        this._countPages = Math.ceil(this._countItems / this.getItemsPerPage());
    }

    updateItemsSize() {
        const items = this.getSlottedItems();
        const sizeInPercent = 100 / this.getItemsPerPage();

        items.forEach((element: HTMLElement) => {
            /* eslint-disable no-param-reassign */
            element.style.flex = `1 0 ${sizeInPercent}%`;
            /* eslint-enable no-param-reassign */
        });

        this.toggleEmptySlots();
    }

    toggleEmptySlots() {
        const itemsPerPage = this.getItemsPerPage();
        const remainingItensOnLastPage =
            this._countPages === 1
                ? itemsPerPage - this._countItems
                : this._countPages * itemsPerPage - this._countItems;

        this.shadowRoot.querySelectorAll(".panel-carousel .ghost").forEach((ghostItem) => {
            ghostItem.remove();
        });

        if (remainingItensOnLastPage > 0) {
            const sizeInPercent = 100 / itemsPerPage;

            for (let i = 0; i < remainingItensOnLastPage; i++) {
                const ghostElement = document.createElement("div");

                ghostElement.style.flex = `1 0 ${sizeInPercent}%`;
                ghostElement.classList.add("ghost");

                this.shadowRoot.querySelector(".panel-carousel").appendChild(ghostElement);
            }
        }
    }

    showItemsFromCurrentPage() {
        const itemsPerPage = this.getItemsPerPage();
        const firstItemOfPage = itemsPerPage * this._currentPage;
        const items = this.getSlottedItems();

        this.shadowRoot.querySelector(".panel-body").scroll({
            top: 0,
            left: (items[firstItemOfPage] as HTMLElement).offsetLeft - this.getPanelPaddingSize(),
            behavior: "smooth"
        });
    }

    changePage(pageNumber: number) {
        this._currentPage = pageNumber;

        this.showItemsFromCurrentPage();
    }

    onClickPreviousPage() {
        let previousPage = this._currentPage - 1;

        if (previousPage < 0) {
            previousPage = this._countPages - 1;
        }

        this.changePage(previousPage);
    }

    onClickNextPage() {
        let nextPage = this._currentPage + 1;

        if (nextPage > this._countPages - 1) {
            nextPage = 0;
        }

        this.changePage(nextPage);
    }

    onSlotChange() {
        if (!this.carousel) return;

        const items = this.getSlottedItems();

        this._countItems = items.length;
        this.updatePagesCount();
        this.updateItemsSize();
        this.showItemsFromCurrentPage();
    }

    onActionsSlotChange() {
        const actionsSlot = this.shadowRoot.querySelector("slot[name=actions]") as HTMLSlotElement;

        this._hasSlottedActions = actionsSlot.assignedElements().length > 0;
    }

    async onScreenModeChange() {
        await this.updateComplete;

        if (!this.carousel) return;

        if (this._deviceController.isMobile) {
            this.cancelCarouselAutoPlay();
        } else {
            this.registerCarouselAutoPlay();
        }

        this._currentPage = 0;
        this.updatePagesCount();
        this.updateItemsSize();
        this.showItemsFromCurrentPage();
    }

    isHeaderVisible() {
        return this.header || (this.collapsible && !this._deviceController.isMobile) || this._hasSlottedActions;
    }

    renderCarouselPages() {
        return map(range(this._countPages), (pageNumber) => {
            const onClick = this.changePage.bind(this, pageNumber);
            const buttonClass = {
                "carousel-page-control": true,
                "active": pageNumber === this._currentPage
            };

            return html`
                <button
                    class=${classMap(buttonClass)}
                    @click=${onClick}
                    aria-label=${`Página ${pageNumber + 1}`}
                ></button>
            `;
        });
    }

    renderCarouselControls() {
        return when(
            this.carousel && this._countItems > this.getItemsPerPage(),
            () => html`
                <div class="carousel-controls">
                    <button @click=${this.onClickPreviousPage}>
                        <atlas-icon name="chevron-left" size="3x"></atlas-icon>
                    </button>
                    ${this.renderCarouselPages()}
                    <button @click=${this.onClickNextPage}>
                        <atlas-icon name="chevron-right" size="3x"></atlas-icon>
                    </button>
                </div>
            `
        );
    }

    renderHeader() {
        if (this._hasSlottedActions) return html``;

        if (this.carousel && !this._deviceController.isMobile) {
            return this.renderCarouselControls();
        }

        if (this.collapsible) {
            return this.renderCollapseButton();
        }

        return html``;
    }

    renderContent() {
        if (this.carousel) {
            const itemsPerPage = this.getItemsPerPage();
            const sizeDiff = itemsPerPage > 1 ? `${itemsPerPage - 1}rem` : `0`;
            const carouselStyles =
                itemsPerPage > 1 ? { paddingLeft: sizeDiff, marginLeft: `-${sizeDiff}`, marginRight: sizeDiff } : {};

            return html`
                <div class="panel-carousel" style=${styleMap(carouselStyles)}>
                    <slot @slotchange=${this.onSlotChange}></slot>
                </div>
            `;
        }

        return this.renderContentWithCollapse(html`<slot></slot>`);
    }

    render() {
        const panelClass = {
            "panel": true,
            "carousel": this.carousel,
            "collapsible": this.collapsible,
            "hide-header": !this.isHeaderVisible()
        };

        const panelBodyClass = {
            "panel-body": true,
            "collapsed": this.collapsible && this._collapsed
        };

        return html`
            <div class=${classMap(panelClass)}>
                <div class="panel-header">
                    <div class="panel-title">
                        <atlas-heading type="h5" theme="primary">${this.header}</atlas-heading>
                        ${when(!!this.description, () => html`<atlas-text muted>${this.description}</atlas-text>`)}
                    </div>
                    ${this.renderHeader()}
                    <slot name="actions" @slotchange=${this.onActionsSlotChange}></slot>
                </div>
                <div class="${classMap(panelBodyClass)}">${this.renderContent()}</div>
                ${when(this._deviceController.isMobile, () => this.renderCarouselControls())}
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-panel": AtlasPanel;
    }
}
