import { html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { customElement, property, state } from "lit/decorators.js";

import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";

import FormControl, { FormControlProps } from "@/components/form/form-control";

import type AtlasCheckbox from "@/components/form/atlas-checkbox/atlas-checkbox";
import type AtlasRadio from "@/components/form/atlas-radio/atlas-radio";

import { emit } from "@/internals/events";
import { Theme } from "@/internals/theme";
import { CardType } from "./types";

import { WithCollapseMixin, WithCollapseProps } from "@/internals/mixins/with-collapse-mixin";
import { filterEmptyNodes } from "@/internals/elements";

import styles from "./atlas-selection-card.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/form/atlas-checkbox/atlas-checkbox";
import "@/components/form/atlas-radio/atlas-radio";

export type SelectionCardProps = FormControlProps &
    WithCollapseProps & {
        "type": CardType;
        "subtitle": string;
        "checked": boolean;
        "subtitle-theme": Theme;
    };

/**
 * @dependency atlas-collapse
 * @dependency atlas-icon
 * @dependency atlas-checkbox
 * @dependency atlas-radio
 *
 * @slot - Usado para escrever as informações extras qque aparecem no corpo do card
 *
 * @prop {CardType} type - O tipo do card
 * @prop {string} subtitle - O subtitulo que seerá apresentado no canto superior direito do card
 * @prop {Theme} subtitle-theme - Define o tema do subtítulo
 * @prop {boolean} checked - Booleano que informa se o card está checado
 *
 * @event {CustomEvent} atlas-selection-card-check - Evento disparado quando o input do card é marcado
 * @event {CustomEvent} atlas-selection-card-uncheck - Evento disparado quando o input do card é desmarcado
 * @event {CustomEvent} atlas-selection-card-change - Evento disparado quando o input do card sofre alteração
 *
 */
@customElement("atlas-selection-card")
export default class AtlasSelectionCard extends WithCollapseMixin(FormControl) {
    static styles = styles;

    @property({ type: String }) type: CardType = "read";

    @property({ type: String }) subtitle = "";

    @property({ type: String, attribute: "subtitle-theme" }) subtitleTheme: Theme;

    @property({ type: Boolean, reflect: true }) checked = false;

    @state() private _hasSlottedBody = false;

    constructor() {
        super();

        this.onClickCard = this.onClickCard.bind(this);
        this.onFormElementChange = this.onFormElementChange.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        if (!this.collapsible) {
            this._collapsed = false;
        } else {
            this._collapsed = !this.checked;
        }
    }

    checkValidity(): boolean {
        return this._valid;
    }

    getFormElement() {
        if (this.type === "checkbox") {
            const formElement = this.shadowRoot.querySelector("atlas-checkbox") as AtlasCheckbox;

            return formElement;
        }

        if (this.type === "radio") {
            const formElement = this.shadowRoot.querySelector("atlas-radio") as AtlasRadio;

            return formElement;
        }

        return null;
    }

    getElementValue() {
        const formElement = this.getFormElement();

        if (!formElement) return null;

        return formElement.getElementValue();
    }

    check() {
        const formElement = this.getFormElement();
        formElement.check();
    }

    uncheck() {
        const formElement = this.getFormElement();
        formElement.uncheck();
    }

    toggleChecked(checked?: boolean) {
        const formElement = this.getFormElement();
        formElement.toggleChecked(checked);
    }

    onClickCard(event: PointerEvent) {
        if (this.type === "read" || this.disabled) return;

        const formElement = this.getFormElement();
        if (event.target === formElement) return;
        if (this.type === "radio" && formElement.checked) return;

        formElement.focus();
        formElement.checked = !formElement.checked;
    }

    onFormElementChange(event: CustomEvent) {
        this.checked = event.detail.checked;

        if (this.checked && this.collapsible) this._collapsed = false;

        emit(this, `atlas-selection-card-${this.checked ? "check" : "uncheck"}`, { detail: event.detail });

        emit(this, "atlas-selection-card-change", { detail: event.detail });
    }

    onChangeSelectionCardBody() {
        const selectionCardBody = this.shadowRoot.querySelector("slot:not([name])") as HTMLSlotElement;
        const assignedNodes = selectionCardBody.assignedNodes({ flatten: true });
        const filteredNodes = filterEmptyNodes(assignedNodes);

        this._hasSlottedBody = filteredNodes.length >= 1;
    }

    @Watch("_valid", true)
    changeFormElementValidationState() {
        this.getFormElement()?.setValidationState(this._valid);
    }

    renderCardInput() {
        return choose(
            this.type,
            [
                [
                    "checkbox",
                    () => html`
                        <atlas-checkbox
                            id="${this.id}"
                            name="${this.name}"
                            value="${this.value}"
                            ?checked=${this.checked}
                            ?disabled=${this.disabled}
                            @atlas-checkbox-change=${this.onFormElementChange}
                        >
                            ${this.label}
                        </atlas-checkbox>
                    `
                ],
                [
                    "radio",
                    () => html`
                        <atlas-radio
                            id="${this.id}"
                            name="${this.name}"
                            value="${this.value}"
                            ?checked=${this.checked}
                            ?disabled=${this.disabled}
                            @atlas-radio-change=${this.onFormElementChange}
                        >
                            ${this.label}
                        </atlas-radio>
                    `
                ]
            ],
            () => html`<label class="atlas-card-label" for="${this.id}"> ${this.label} </label>`
        );
    }

    renderCardSubtitle() {
        const selectionCardHeaderSubtitleClass = {
            "atlas-card-header-subtitle": true,
            "has-input": this.type !== "read",
            [`color-${this.subtitleTheme}`]: this.subtitleTheme
        };

        return when(
            !!this.subtitle,
            () => html`<span class="${classMap(selectionCardHeaderSubtitleClass)}"> ${this.subtitle} </span>`
        );
    }

    renderCardHeader() {
        return html`
            <div class="atlas-card-header">
                ${this.renderCardInput()} ${this.renderCardSubtitle()}
                ${when(this.collapsible, () => this.renderCollapseButton())}
            </div>
        `;
    }

    render() {
        const cardClass = {
            "atlas-read-card": this.type === "read",
            "atlas-form-card": true,
            "atlas-form-card-disabled": this.disabled,
            "checked": this.type !== "read" && this.checked,
            "is-invalid": !this._valid,
            "hide-selection-card-body": !this._hasSlottedBody
        };

        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <div class="${classMap(cardClass)}" role="button" @click=${this.onClickCard}>
                ${this.renderCardHeader()}
                ${this.renderContentWithCollapse(html`
                    <div class="atlas-card-body">
                        <slot @slotchange=${this.onChangeSelectionCardBody}></slot>
                    </div>
                `)}
            </div>
            ${this.renderValidationMessage()} ${this.renderHelperText()}
        `;
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-selection-card": AtlasSelectionCard;
    }
}
