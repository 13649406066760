import { html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";
import Dropzone from "dropzone";

import { showAlert } from "@/helpers/notifications";
import { emit } from "@/internals/events";
import { DropzoneFile } from "@/internals/basic-types";
import DropzoneValidator from "@/internals/validators/dropzone-validator";

import FormElement from "@/components/form/form-element";
import styles from "./atlas-dropzone.scss";

import "@/components/display/atlas-icon/atlas-icon";
import "@/components/display/atlas-link/atlas-link";

/**
 * @dependency atlas-icon
 * @dependency atlas-link
 *
 * @prop {string} url - A Url de upload de arquivos
 * @prop {string} name - O name do campo de upload, refletirá sobre o parâmetro enviado ao endpoint
 * @prop {string} accepted-files - Os tipos de arquivos aceitos pelo dropzone, pode seguir tipos definidos igual "images", "pdf", ou pode ser uma coletânea escolhendo quais devem ser utilizado ex: ".pdf, .jpg, .gif".
 * @prop {number} max-files - A quantidade de arquivos que podem ser enviados.
 * @prop {number} max-file-size - O tamanho máximo que o arquivo pode ter em (MiB).
 * @prop {string} warning-max-files-exceeded - O texto que será emitido no alerta quando o número máximo de imagens for alcançado.
 * @prop {string} message-drag-max-files-exceeded - O texto que aparecerá no drag n drop quando o número máximo de imagens for alcançado.
 * @prop {string} drag-area-selector - O seletor desejado para que se expanda o drag n drop, ex: ".js-major-drag-area", necessita a propriedade "expanded" conjuntamente.
 * @prop {Boolean} expanded - Define se o drag n drop deve ter um tamanho maior que o dropzone, se não for explicitado um "dragAreaSelector", então expandirá para o body.
 *
 * @event {CustomEvent} atlas-dropzone-addedfile - Evento disparado quando é adicionado um arquivo.
 * @event {CustomEvent} atlas-dropzone-removedfile - Evento disparado quando é removido um arquivo.
 * @event {CustomEvent} atlas-dropzone-success - Evento disparado quando o arquivo é adicionado com sucesso.
 * @event {CustomEvent} atlas-dropzone-error - Evento disparado quando no processo de adicionar um arquivo ocorre um erro.
 * @event {CustomEvent} atlas-dropzone-sending - Evento disparado quando o arquivo está sendo enviado para a url.
 * @event {CustomEvent} atlas-dropzone-complete - Evento disparado quando o arquivo acabou de ser enviado para a url.
 *
 * @tag atlas-dropzone
 */
@customElement("atlas-dropzone")
export default class AtlasDropzone extends FormElement {
    static styles = styles;

    @property({ type: String }) url: string;

    @property({ type: String }) name: string;

    @property({ type: String, attribute: "accepted-files" }) acceptedFiles: string;

    @property({ type: Number, attribute: "max-files" }) maxFiles: number;

    @property({ type: Number, attribute: "max-file-size" }) maxFileSize: number = 20;

    @property({ type: String, attribute: "warning-max-files-exceeded" }) warningMaxFilesExceeded: string =
        "Quantidade máxima de arquivos atingida";

    @property({ type: String, attribute: "message-drag-max-files-exceeded" }) messageDragMaxFilesExceeded: string =
        "Quantidade máxima de arquivos atingida";

    @property({ type: String, attribute: "drag-area-selector" }) dragAreaSelector: string;

    @property({ type: Boolean }) preview = false;

    @property({ type: Boolean }) expanded = false;

    @state() protected maxFilesReached = false;

    @state() protected hasFiles = false;

    @state() protected uploadedFiles: Array<DropzoneFile> = [];

    @state() private _uploadingFiles = false;

    @state() private _progress: number;

    totalFiles = 0;

    sendingFiles = 0;

    dropzone: Dropzone;

    private _countingFiles = false;

    private _parallelUploadFiles = 4;

    constructor() {
        super();

        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onRemovedFile = this.onRemovedFile.bind(this);
        this.onAddedFile = this.onAddedFile.bind(this);
        this.onSending = this.onSending.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.resetUploadingState = this.resetUploadingState.bind(this);
        this.updateUploadProgress = this.updateUploadProgress.bind(this);
        this.cancelUpload = this.cancelUpload.bind(this);
    }

    connectedCallback() {
        this.addValidator(new DropzoneValidator());

        super.connectedCallback?.();

        this.updateComplete.then(() => {
            this.createDropzoneInstance();
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback?.();

        const { dropzoneContainer } = this.getDropzoneContainers();

        dropzoneContainer.removeEventListener("dropzone:success", this.onSuccess);
        dropzoneContainer.removeEventListener("dropzone:error", this.onError);
        dropzoneContainer.removeEventListener("dropzone:removedfile", this.onRemovedFile);
        dropzoneContainer.removeEventListener("dropzone:addedfile", this.onAddedFile);
        dropzoneContainer.removeEventListener("dropzone:sending", this.onSending);
        dropzoneContainer.removeEventListener("dropzone:complete", this.onComplete);
        dropzoneContainer.removeEventListener("dropzone:queuecomplete", this.resetUploadingState);

        if (!this.preview) {
            dropzoneContainer.removeEventListener("dropzone:totaluploadprogress", this.updateUploadProgress);
        }
    }

    isUploadingFiles() {
        return this._uploadingFiles;
    }

    private createDropzoneInstance() {
        if (this.dropzone) {
            return;
        }

        const { dropzoneContainer, previewsContainerReference, clickableReference } = this.getDropzoneContainers();

        dropzoneContainer.setAttribute("data-message-drag-max-files-exceeded", this.messageDragMaxFilesExceeded);

        this.dropzone = new Dropzone(dropzoneContainer, {
            url: this.url,
            paramName: this.name,
            addRemoveLinks: true,
            previewsContainer: previewsContainerReference,
            clickable: clickableReference,
            maxFiles: this.maxFiles ? this.maxFiles : null,
            maxFilesize: this.maxFileSize,
            acceptedFiles: this.getAcceptedFiles(),
            dictFileTooBig: "Tamanho de arquivo(s) ultrapassa o permitido",
            dictInvalidFileType: "Formato de arquivo não permitido.",
            dictMaxFilesExceeded: this.warningMaxFilesExceeded,
            dictUploadCanceled: "Envio cancelado.",
            dictCancelUploadConfirmation: "Deseja realmente cancelar o envio do arquivo?",
            dictRemoveFile: "Remover arquivo",
            dictRemoveFileConfirmation: null,
            thumbnailMethod: "contain",
            // @ts-expect-error
            disablePreviews: !this.preview,
            parallelUploads: this._parallelUploadFiles
        });

        dropzoneContainer.addEventListener("dropzone:success", this.onSuccess);
        dropzoneContainer.addEventListener("dropzone:error", this.onError);
        dropzoneContainer.addEventListener("dropzone:removedfile", this.onRemovedFile);
        dropzoneContainer.addEventListener("dropzone:addedfile", this.onAddedFile);
        dropzoneContainer.addEventListener("dropzone:sending", this.onSending);
        dropzoneContainer.addEventListener("dropzone:complete", this.onComplete);
        dropzoneContainer.addEventListener("dropzone:queuecomplete", this.resetUploadingState);

        if (!this.preview) {
            dropzoneContainer.addEventListener("dropzone:totaluploadprogress", this.updateUploadProgress);
        }
    }

    private getDropzoneContainers() {
        let dropzoneContainer: any = this;

        if (this.expanded) {
            if (this.dragAreaSelector) {
                dropzoneContainer = document.querySelector(`${this.dragAreaSelector}`);
            } else {
                dropzoneContainer = document.body;
            }
        }

        const clickableReference = this.shadowRoot.querySelector(".atlas-dropzone-upload-clickable") as HTMLElement;
        const previewsContainerReference = this.shadowRoot.querySelector(".atlas-dropzone-preview") as HTMLElement;

        return { dropzoneContainer, clickableReference, previewsContainerReference };
    }

    getDropzoneInstance() {
        return this.dropzone;
    }

    getElementValue() {
        return this.uploadedFiles;
    }

    getAcceptedFiles() {
        const filesByType = {
            images: ".jpg, .jpeg, .gif, .png",
            docs: ".pdf, .doc, .docx",
            pdf: ".pdf",
            imagesOrPdf: ".jpg, .jpeg, .gif, .png, .pdf",
            all: "*"
        }[this.acceptedFiles];

        return filesByType || this.acceptedFiles;
    }

    getFilesPreviewElement() {
        const allFiles = this.dropzone.getAcceptedFiles();

        return allFiles.map((file: any) => file.previewElement);
    }

    emitDropzoneEvent(event: CustomEvent) {
        const eventName = event.type.split(":")[1];

        event.stopPropagation();

        emit(this, `atlas-dropzone-${eventName}`, {
            detail: { ...event.detail }
        });
    }

    onRemovedFile(event: CustomEvent) {
        const file = event.detail.args[0];
        const { uuid } = file.upload;

        if (this._countingFiles) {
            this.totalFiles -= 1;
        } else if (!this._uploadingFiles) {
            this.uploadedFiles = this.uploadedFiles.filter((uploadedFile) => uploadedFile.uuid !== uuid);
            this.value = this.uploadedFiles.toString();
            this.resetUploadingState();
        }

        this.updateLayoutIfHasFilesInQueue();
        this.emitDropzoneEvent(event);
    }

    onAddedFile(event: CustomEvent) {
        this._countingFiles = true;

        const file = event.detail.args[0];

        this.totalFiles += 1;

        if (this.preview) {
            const { previewElement } = file;

            const previewImage = previewElement.querySelector(".dz-image");
            const icon = document.createElement("atlas-icon");
            icon.name = "file";
            icon.size = "5x";
            previewImage.innerHTML += "<span class='dz-default-file-icon'></span>";
            previewImage.querySelector(".dz-default-file-icon").append(icon);

            const previewFilenameContainer = previewElement.querySelector(".dz-filename");

            const previewFilename = previewFilenameContainer.querySelector("[data-dz-name]");
            previewFilename.innerHTML = file.name.replace(/\.[^/.]+$/, "");

            const previewExtensionName = document.createElement("span");
            previewExtensionName.innerHTML = `.${file.name.split(".").pop()}`;

            previewFilenameContainer.appendChild(previewExtensionName);
        }

        this.updateRemoveLinkIcon(file);
        this.updateLayoutIfHasFilesInQueue();
        this.emitDropzoneEvent(event);
    }

    onSending(event: CustomEvent) {
        const file = event.detail.args[0];
        this.updateRemoveLinkIcon(file);

        this._countingFiles = false;
        this._uploadingFiles = true;

        this.sendingFiles += 1;

        this.emitDropzoneEvent(event);
    }

    onComplete(event: CustomEvent) {
        const file = event.detail.args[0];

        this.updateRemoveLinkIcon(file);
        this.emitDropzoneEvent(event);
    }

    onSuccess(event: CustomEvent) {
        const file = event.detail.args[0];
        const response = event.detail.args[1];

        const { uuid } = file.upload;
        const { tempFileId, tempFileName } = response;

        if (!response.success) {
            this.onError(event);
            return;
        }

        this.uploadedFiles = [...this.uploadedFiles, { uuid, tempFileId, tempFileName }];
        this.value = this.uploadedFiles.toString();

        this.emitDropzoneEvent(event);
    }

    onError(event: CustomEvent) {
        const response = event.detail.args[1];
        const responseText = response.messageHtml || response;

        if (/<\/?[a-z][\s\S]*>/i.test(responseText)) {
            const responseHTML = new DOMParser().parseFromString(responseText, "text/html");
            showAlert(responseHTML.body.textContent, "warning");
        } else {
            showAlert(responseText, "warning");
        }

        this.removeFile(event);
        this.emitDropzoneEvent(event);
    }

    removeFile(event: CustomEvent) {
        const file = event.detail.args[0];
        this.dropzone.removeFile(file);
        this.emitDropzoneEvent(event);
    }

    resetUploadingState() {
        this._uploadingFiles = false;
        this._progress = 0;
        this.sendingFiles = 0;
        this.totalFiles = 0;
        this.setValidationState(true);
    }

    updateUploadProgress(event: CustomEvent) {
        const currentTotalProgress = event.detail.args[0];

        this._progress = this._progress > currentTotalProgress ? this._progress : currentTotalProgress;
    }

    updateRemoveLinkIcon(file: any) {
        if (!this.preview) return;

        const removeLink = file._removeLink;
        const icon = document.createElement("atlas-icon");
        icon.name = "x";
        removeLink.innerHTML = "";
        removeLink.append(icon);
    }

    updateLayoutIfHasFilesInQueue() {
        const numberOfFilesInQueue = this.dropzone.files.length;

        const { dropzoneContainer } = this.getDropzoneContainers();

        if (numberOfFilesInQueue >= this.maxFiles) {
            dropzoneContainer.classList.add("atlas-dropzone-max-files-reached");
            this.maxFilesReached = true;
            return;
        }

        dropzoneContainer.classList.remove("atlas-dropzone-max-files-reached");
        this.maxFilesReached = false;

        if (numberOfFilesInQueue === 0) {
            this.hasFiles = false;
            return;
        }

        this.hasFiles = true;
    }

    cancelUpload(event: PointerEvent) {
        event.stopPropagation();

        for (const file of this.dropzone.files.slice()) {
            if (file.status === Dropzone.UPLOADING) {
                this.dropzone.removeFile(file);
            }
        }
    }

    renderUploadIcon() {
        const uploadIconTheme = this._showValidationState && !this._valid ? "danger" : "primary";

        return html`<atlas-icon name="upload" theme="${uploadIconTheme}" size="6x"></atlas-icon>`;
    }

    renderUploadingBar() {
        /* eslint-disable lit-a11y/click-events-have-key-events */
        return html`
            <div class="atlas-dropzone-uploading-files">
                <div class="atlas-dropzone-uploading-files-bar">
                    <div class="atlas-dropzone-uploading-files-progress-bar" style="width: ${this._progress}%"></div>
                </div>
                <div class="atlas-dropzone-uploading-files-info">
                    <div>Carregando ${this.sendingFiles}/${this.totalFiles}</div>
                    <atlas-link href="javascript:void(0)" @click=${this.cancelUpload}>Cancelar</atlas-link>
                </div>
            </div>
        `;
        /* eslint-enable lit-a11y/click-events-have-key-events */
    }

    renderDropzoneInfo() {
        return when(
            !this.preview && this._uploadingFiles,
            () => this.renderUploadingBar(),
            () => html`
                <span class="atlas-dropzone-upload-text-full">
                    Clique aqui para selecionar o arquivo ou arraste-o para cá
                </span>
                <span class="atlas-dropzone-upload-text-mini">Adicione ou arraste os arquivos</span>
                <span class="atlas-dropzone-upload-text-mobile">Adicione os arquivos</span>
            `
        );
    }

    render() {
        const dropzoneClasses = {
            "atlas-dropzone": true,
            "atlas-dropzone-max-files-reached": this.maxFilesReached,
            "atlas-dropzone-has-files": this.hasFiles && this.preview,
            "atlas-dropzone-uploading": !this.preview && this._uploadingFiles,
            "is-invalid": this._showValidationState && !this._valid
        };

        return html`
            <div class="${classMap(dropzoneClasses)}">
                <div class="atlas-dropzone-label-container">
                    <div class="atlas-dropzone-preview">
                        <div class="atlas-dropzone-upload-clickable">
                            ${this.renderUploadIcon()} ${this.renderDropzoneInfo()}
                        </div>
                    </div>
                </div>
            </div>
            ${this.renderValidationMessage()}
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-dropzone": AtlasDropzone;
    }
}
