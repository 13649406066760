import { html } from "lit";
import { customElement, property, query } from "lit/decorators.js";

import { emit } from "@/internals/events";
import { Watch } from "@/decorators/watch";

import Inputmask from "@/vendors/inputmask-utils";
import Datepicker from "@/vendors/datepicker-utils";
import DateValidator from "@/internals/validators/date-validator";
import AtlasInput, { InputProps } from "@/components/form/atlas-input/atlas-input";

import styles from "./atlas-datepicker.scss";
import "@/components/display/atlas-icon/atlas-icon";

export type DatepickerProps = InputProps & {
    "min-date": string;
    "max-date": string;
    "prevent-past-date": string;
    "prevent-later-date": string;
};

/**
 * O componente datepicker é um input que possui um calendário para escolha de datas
 *
 * @extends atlas-input
 * @dependency atlas-icon
 *
 * @prop {string} min-date - Define uma data mínima que o input deve aceitar
 * @prop {string} max-date - Define uma data máxima que o input deve aceitar
 * @prop {boolean} prevent-past-date - Define se o datepicker pode ou não aceitar datas anteriores ao dia de hoje
 * @prop {boolean} prevent-later-date - Define se o datepicker pode ou não aceitar datas posteriores ao dia de hoje
 *
 * @event {CustomEvent} atlas-datepicker-change - Evento lançado quando a data selecionada é alterada
 *
 * @tag atlas-datepicker
 */
@customElement("atlas-datepicker")
export default class AtlasDatepicker extends AtlasInput {
    static styles = styles;

    @property({ type: String }) placeholder = "__/__/____";

    @property({ type: String, attribute: "min-date" }) minDate: string;

    @property({ type: String, attribute: "max-date" }) maxDate: string;

    @property({ type: Boolean, reflect: true, attribute: "prevent-past-date" }) preventPastDate: boolean;

    @property({ type: Boolean, reflect: true, attribute: "prevent-later-date" }) preventLaterDate: boolean;

    @query(".atlas-datepicker")
    private _datepickerContainer: HTMLElement;

    private _datepicker: Datepicker;

    private _maskInstance: Inputmask.Instance;

    constructor() {
        super();

        this.onShowDatepicker = this.onShowDatepicker.bind(this);
        this.onHideDatepicker = this.onHideDatepicker.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onKeyboardClick = this.onKeyboardClick.bind(this);
        this.onClickCalendarIcon = this.onClickCalendarIcon.bind(this);
        this.onChangeScreenType = this.onChangeScreenType.bind(this);
    }

    connectedCallback(): void {
        super.connectedCallback?.();

        this._deviceController.setScreenChangeCallback(this.onChangeScreenType);
        this.addEventListener("atlas-input-icon-click", this.onClickCalendarIcon);

        this.updateComplete.then(() => {
            this.createDatepicker();
            this.addValidator(new DateValidator());

            this.inputMode = "numeric";
            this.icon = "calendar";
            this._maskInstance = Inputmask({ alias: "datepicker" }).mask(this._input);
        });
    }

    disconnectedCallback(): void {
        super.disconnectedCallback?.();

        this.removeEventListener("atlas-input-icon-click", this.onClickCalendarIcon);
        this._input.removeEventListener("show", this.onShowDatepicker);
        this._input.removeEventListener("hide", this.onHideDatepicker);
        this._input.removeEventListener("changeDate", this.onChangeDate);
    }

    getDatepickerInstance() {
        return this._datepicker;
    }

    getUnmaskedValue() {
        return this._maskInstance.unmaskedvalue();
    }

    createDatepicker() {
        if (this._datepicker) {
            return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let title = Datepicker.getDateTitle(today);

        if (this.value !== "") {
            const dateSplit = this.value.split("/");
            const dateValue = new Date(
                parseInt(dateSplit[2], 10),
                parseInt(dateSplit[1], 10) - 1,
                parseInt(dateSplit[0], 10)
            );

            title = Datepicker.getDateTitle(dateValue);
        }

        this._datepicker = new Datepicker(this._input, {
            autohide: true,
            format: Datepicker.dateFormat,
            maxView: 2,
            language: "pt-BR",
            buttonClass: "atlas-datepicker-button",
            nextArrow: "<span class='next-arrow'></span>",
            prevArrow: "<span class='prev-arrow'></span>",
            minDate: Datepicker.getDatepickerMinDate(this.preventPastDate, this.minDate),
            maxDate: Datepicker.getDatepickerMaxDate(this.preventLaterDate, this.maxDate),
            title,
            container: this._datepickerContainer,
            beforeShowDay: (date: any) => ({ content: `<span>${date.getDate()}</span>` }),
            beforeShowMonth: (date: any) => ({
                content: `<span>${Datepicker.locales["pt-BR"].monthsShort[date.getMonth()]}</span>`
            }),
            beforeShowYear: (date: any) => ({ content: `<span>${date.getFullYear()}</span>` }),
            beforeShowDecade: (date: any) => ({ content: `<span>${date.getFullYear()}</span>` })
        });

        this.createNextPrevArrows();
        this.createCloseButton();

        this._input.addEventListener("show", this.onShowDatepicker);
        this._input.addEventListener("hide", this.onHideDatepicker);
        this._input.addEventListener("changeDate", this.onChangeDate);

        this._datepickerContainer.querySelector(".datepicker-title").addEventListener("click", this.onKeyboardClick);
        this.onChangeScreenType();
    }

    createNextPrevArrows() {
        const prevArrow = document.createElement("atlas-icon");
        prevArrow.name = "chevron-left";
        prevArrow.theme = "secondary";

        const nextArrow = document.createElement("atlas-icon");
        nextArrow.name = "chevron-right";
        nextArrow.theme = "secondary";

        this.shadowRoot.querySelector(".next-arrow").append(nextArrow);
        this.shadowRoot.querySelector(".prev-arrow").append(prevArrow);
    }

    createCloseButton() {
        const closeButton = document.createElement("button");
        closeButton.innerHTML = "Fechar";
        closeButton.classList.add("atlas-datepicker-button");
        closeButton.classList.add("close-btn");
        closeButton.onclick = () => {
            this._datepicker.hide();
        };

        const footerControls = this.shadowRoot.querySelector(".datepicker-footer .datepicker-controls");
        footerControls.appendChild(closeButton);
    }

    updateTitle(element: any) {
        const title = element.innerHTML.split("/");

        /* eslint-disable no-param-reassign */
        element.innerHTML = "";
        /* eslint-enable no-param-reassign */

        const titleYear = document.createElement("span");
        titleYear.classList.add("datepicker-title-year");
        const year = title[0];
        titleYear.innerHTML = year;

        const titleDate = document.createElement("span");
        titleDate.classList.add("datepicker-title-date");
        const date = title[1];
        titleDate.innerHTML = date;

        const icon = document.createElement("atlas-icon");
        icon.name = "keyboard";
        icon.size = "4x";

        element.appendChild(titleYear);
        element.appendChild(titleDate);
        element.appendChild(icon);
    }

    @Watch(["minDate", "maxDate", "preventPastDate", "preventLaterDate"], true)
    onChangeOptions() {
        this._datepicker?.setOptions({
            minDate: Datepicker.getDatepickerMinDate(this.preventPastDate, this.minDate),
            maxDate: Datepicker.getDatepickerMaxDate(this.preventLaterDate, this.maxDate)
        });
    }

    onChangeScreenType() {
        if (!this._datepicker) return;

        this.hasIconEvent = this._deviceController.isMobile;

        this._datepicker.setOptions({
            showOnFocus: !this._deviceController.isMobile,
            showOnClick: !this._deviceController.isMobile
        });
    }

    onClickCalendarIcon() {
        this._datepicker.show();
    }

    onChangeValue() {
        if (!Inputmask.isValid(this.value, { alias: "datepicker" })) {
            this.value = Inputmask.format(this.value, { alias: "datepicker" });
        }

        super.onChangeValue();
    }

    onHideDatepicker() {
        const datepickerTitleElement = this._datepickerContainer.querySelector(".datepicker-title");

        datepickerTitleElement.innerHTML = Datepicker.getDateTitle(this._datepicker.getDate());

        if (this._deviceController.isMobile) {
            const backdrop = this.shadowRoot.querySelector(".atlas-datepicker-backdrop");
            backdrop.classList.remove("show");
        }
    }

    onShowDatepicker() {
        const datepickerTitleElement = this._datepickerContainer.querySelector(".datepicker-title");
        this.updateTitle(datepickerTitleElement);

        if (this._deviceController.isMobile) {
            const backdrop = this.shadowRoot.querySelector(".atlas-datepicker-backdrop");
            backdrop.classList.add("show");
        }

        this._input.setAttribute("inputmode", "none");
    }

    onKeyboardClick() {
        this._input.setAttribute("inputmode", "numeric");
        this._datepicker.hide();
    }

    onChangeDate() {
        if (this.value !== this._input.value) {
            this.value = this._input.value;
            emit(this, "atlas-datepicker-change");
        }
    }

    render() {
        return html`
            <div class="atlas-datepicker-backdrop"></div>
            <div class="atlas-datepicker">${super.render()}</div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-datepicker": AtlasDatepicker;
    }
}
