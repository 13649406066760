import Analysis from "@/assets/illustrations/analysis.svg";
import Authentication from "@/assets/illustrations/authentication.svg";
import Bank from "@/assets/illustrations/bank.svg";
import Folder from "@/assets/illustrations/folder.svg";
import PaperAirplane from "@/assets/illustrations/paper airplane.svg";
import Papers from "@/assets/illustrations/papers.svg";

const illustrations: { [key: string]: any } = {
    "analysis": Analysis,
    "authentication": Authentication,
    "bank": Bank,
    "folder": Folder,
    "paper airplane": PaperAirplane,
    "papers": Papers
};

export default illustrations;
