/**
 * Emite um evento customizado, aplicando algumas opções padrões
 * @param {HTMLElement} element - O elemento que vai emitir o evento
 * @param {string} name - O nome do evento a ser emitido
 * @param {object} options - Opções que serão passado ao evento (opcional)
 * @returns Evento que foi emitido
 */
export function emit(el: HTMLElement, name: string, options?: object): CustomEvent {
    const event = new CustomEvent(name, {
        bubbles: true,
        cancelable: true,
        composed: true,
        detail: {},
        ...options
    });

    el.dispatchEvent(event);
    return event;
}
