import { LitElement, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

import { emit } from "@/internals/events";

import loadingStyles from "./atlas-loading.scss";

import "@/components/display/atlas-heading/atlas-heading";

export type LoadingProps = {
    message: string;
};

/**
 * Loading é um componente que representa um estado de carregamento na tela após uma ação ser tomada
 *
 * @prop {string} message - A mensagem que informará a ação que está sendo carregada
 *
 * @event {CustomEvent} atlas-loading-show - Evento disparado quando o Loading é exibido
 * @event {CustomEvent} atlas-loading-close - Evento disparado quando o Loading é fechado
 *
 * @tag atlas-loading
 */
@customElement("atlas-loading")
export class AtlasLoading extends LitElement {
    static styles = loadingStyles;

    @property({ type: String }) message: String;

    @state() visible = false;

    constructor() {
        super();

        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
    }

    firstUpdated() {
        setTimeout(this.show, 100);
    }

    show() {
        this.visible = true;
        emit(this, "atlas-loading-show");
    }

    close() {
        this.visible = false;
        emit(this, "atlas-loading-close");
    }

    destroy() {
        this.close();

        setTimeout(() => {
            this.remove();
        }, 150);
    }

    render() {
        const loadingClass = {
            loading: true,
            show: this.visible
        };

        /* eslint-disable max-len */
        return html`
            <div class="${classMap(loadingClass)}">
                <div class="loading-image">
                    <svg class="circular-loader" viewBox="25 25 50 50">
                        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke-width="2"></circle>
                    </svg>
                    <svg
                        class="logo-loading"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 80 80"
                        width="80"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M60.5525 22.044C58.9355 23.0252 61.2169 23.507 49.8957 30.3754C48.5471 31.1938 37.0367 38.3658 41.9031 46.7918C42.6115 48.0194 44.2197 50.7452 45.0051 52.0784H34.9951C35.7805 50.7452 37.3887 48.0194 38.0971 46.7918C42.9635 38.3658 31.4531 31.1938 30.1045 30.3754C18.7833 23.507 21.0625 23.0274 19.4477 22.044C19.0429 21.7998 18.5567 22.6402 18.4049 22.902C17.3665 24.6994 18.2707 31.9594 21.7533 34.0736C20.7941 35.7324 22.5101 40.3744 26.1005 42.5524C24.6045 45.1396 27.0267 48.0106 32.6631 51.6648L29.0727 57.4288C28.9449 57.6421 28.8779 57.8863 28.8791 58.135C28.8791 58.8434 29.5149 59.4 30.3245 59.4C30.8482 59.4153 31.3407 59.1515 31.6181 58.707L34.1547 54.5842H45.8785L48.4217 58.6872C48.6835 59.1338 49.1697 59.4 49.7285 59.4C50.5381 59.4 51.1739 58.8434 51.1739 58.135C51.1777 57.8736 51.0967 57.6181 50.9429 57.4068L47.3525 51.6538C52.9779 48.0062 55.3935 45.1374 53.8997 42.5524C57.4901 40.3744 59.2061 35.7324 58.2469 34.0736C61.7295 31.9616 62.6337 24.7016 61.5953 22.9042C61.4435 22.6402 60.9573 21.7998 60.5525 22.0462V22.044Z"
                        ></path>
                    </svg>
                </div>
                <div class="loading-description">
                    <atlas-heading type="h3">Aguarde</atlas-heading>
                    <atlas-heading type="h4">${this.message}</atlas-heading>
                </div>
            </div>
        `;
        /* eslint-enable max-len */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-loading": AtlasLoading;
    }
}
