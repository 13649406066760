import { html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

import { Watch } from "@/decorators/watch";
import { emit } from "@/internals/events";
import Datepicker from "@/vendors/datepicker-utils";

import AtlasElement from "@/components/atlas-element";
import type { AtlasElementProps } from "@/components/atlas-element";

import { CalendarFlag, CalendarFlaggedDate } from "./types";
import styles from "./atlas-calendar.scss";
import "@/components/display/atlas-tooltip/atlas-tooltip";

export type CalendarProps = AtlasElementProps & {
    "flags": string;
    "flagged-dates": string;
};

/**
 * @dependency atlas-tooltip
 *
 * @prop {CalendarFlag[]} flags - Define os tipos de flags que podem ser adicionadas no calendário (As flags são badges que aparecem nas datas informadas através do atributo `flagged-dates`)
 * @prop {CalendarFlaggedDate[]} flagged-dates - Define as datas que serão marcadas no calendário com algum evento (Os tipos de flag são definidos pelo atributo `flags`)
 *
 * @event {CustomEvent} atlas-calendar-date-change - Evento disparado ao selecionar uma data ou um intervalo de datas no calendário
 * @event {CustomEvent} atlas-calendar-month-change - Evento disparado ao mudar de mês no calendário
 *
 * @tag atlas-calendar
 */
@customElement("atlas-calendar")
export default class AtlasCalendar extends AtlasElement {
    static styles = styles;

    @property({ type: Array }) flags: CalendarFlag[] = [];

    @property({ type: Array, attribute: "flagged-dates" }) flaggedDates: CalendarFlaggedDate[] = [];

    @state() private _rangeStart: number;

    @state() private _rangeEnd: number;

    @state() private _rangeHover: number;

    @state() private _enableHoverRange = false;

    @state() private _currentMonth: number;

    @query(".atlas-calendar")
    private _calendarContainer: HTMLElement;

    private _datepicker: Datepicker;

    connectedCallback(): void {
        super.connectedCallback?.();

        this.onSelectDate = this.onSelectDate.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.onMouseLeaveCalendar = this.onMouseLeaveCalendar.bind(this);
        this.onChangeSkeletonLoading = this.onChangeSkeletonLoading.bind(this);

        this.addEventListener("atlas-element-change-skeleton", this.onChangeSkeletonLoading);

        this.updateComplete.then(() => {
            this.createCalendar();
        });
    }

    disconnectedCallback(): void {
        this.removeEventListener("atlas-element-change-skeleton", this.onChangeSkeletonLoading);
    }

    getDatepickerInstance() {
        return this._datepicker;
    }

    onChangeSkeletonLoading() {
        this.createCalendar();
    }

    getDates() {
        let startDate = this._rangeStart;
        let endDate = this._rangeEnd;

        if (endDate && startDate > endDate) {
            [startDate, endDate] = [endDate, startDate];
        }

        return {
            startDate: new Date(startDate),
            endDate: endDate ? new Date(endDate) : null
        };
    }

    getFormattedDates() {
        const { startDate, endDate } = this.getDates();

        return {
            startDate: Datepicker.formatDate(startDate, Datepicker.dateFormat),
            endDate: endDate ? Datepicker.formatDate(endDate, Datepicker.dateFormat) : null
        };
    }

    setFlags(flags: CalendarFlag[]) {
        this.flags = [...flags];
    }

    setFlaggedDates(flaggedDates: CalendarFlaggedDate[]) {
        this.flaggedDates = [...flaggedDates];
    }

    buildLeftArrowIcon() {
        if (this.skeletonLoading) {
            return `<div class="skeleton" style="width:1rem; height: 1rem"></div>`;
        }

        return `<i class="atlas-icon ati-chevron-left ati-3x color-secondary"></i>`;
    }

    buildRightArrowIcon() {
        if (this.skeletonLoading) {
            return `<div class="skeleton" style="width:1rem; height: 1rem"></div>`;
        }

        return `<i class="atlas-icon ati-chevron-right ati-3x color-secondary"></i>`;
    }

    buildDayCell(date: any) {
        if (this.skeletonLoading) {
            return `
                <span style="width: 50%; height: 2rem">
                    <div class="skeleton" style="width: 100%; height: 1rem"></div>
                </span>
            `;
        }

        return `<span class="cell-content ${this.skeletonLoading ? "skeleton" : ""}">${date.getDate()}</span>`;
    }

    createCalendar() {
        this._datepicker = new Datepicker(this._calendarContainer, {
            format: Datepicker.dateFormat,
            maxView: 2,
            maxNumberOfDates: 3,
            language: "pt-BR",
            buttonClass: `atlas-datepicker-button`,
            prevArrow: this.buildLeftArrowIcon(),
            nextArrow: this.buildRightArrowIcon(),
            beforeShowDay: (date: any) => ({
                content: this.buildDayCell(date)
            }),
            beforeShowMonth: (date: any) => ({
                content: `
                    <span class="cell-content">
                        ${Datepicker.locales["pt-BR"].monthsShort[date.getMonth()]}
                    </span>
                `
            }),
            beforeShowYear: (date: any) => ({
                content: `<span class="cell-content">${date.getFullYear()}</span>`
            }),
            beforeShowDecade: (date: any) => ({
                content: `<span class="cell-content">${date.getFullYear()}</span>`
            })
        });

        if (this.skeletonLoading) {
            this._calendarContainer.querySelector(
                ".view-switch"
            ).innerHTML = `<div class="skeleton" style="width: 50%; height: 16px"></div>`;

            /* eslint-disable no-param-reassign */
            this._calendarContainer.querySelectorAll(".dow").forEach((dowElement) => {
                dowElement.innerHTML = `<div class="skeleton" style="width: 50%; height: 16px"></div>`;
            });
            /* eslint-enable no-param-reassign */

            return;
        }

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        this._datepicker.setDate(currentDate.getTime());
        this._rangeStart = currentDate.getTime();
        this._currentMonth = currentDate.getMonth();

        this._calendarContainer.addEventListener("changeMonth", this.onChangeMonth);
        this._calendarContainer.addEventListener("changeView", this.onChangeView);
        this._calendarContainer.addEventListener("changeDate", this.onSelectDate);
        this._calendarContainer.addEventListener("mouseleave", this.onMouseLeaveCalendar);

        this.setDateLimit();
        this.addHoverListener();
        this.setFlaggedDatesOnCalendar();
    }

    addHoverListener() {
        this._calendarContainer.querySelectorAll(".datepicker-grid .datepicker-cell").forEach((cell: HTMLElement) => {
            cell.addEventListener("mouseover", () => {
                if (!this._enableHoverRange) return;

                this._rangeHover = parseInt(cell.dataset.date, 10);
                this.setRangesOnCalendar();
            });
        });
    }

    clearRanges() {
        this._rangeStart = null;
        this._rangeEnd = null;
    }

    onMouseLeaveCalendar() {
        this._rangeHover = null;
        this.setRangesOnCalendar();
    }

    onChangeMonth(event: CustomEvent) {
        this._currentMonth = event.detail.viewDate.getMonth();

        this.addHoverListener();
        this.setFlaggedDatesOnCalendar();
        this.setRangesOnCalendar();

        emit(this, "atlas-calendar-month-change", {
            detail: {
                month: this._currentMonth
            }
        });
    }

    onChangeView(event: CustomEvent) {
        const { viewId, viewDate } = event.detail;

        if (viewId === 0 && viewDate.getMonth() === this._currentMonth) {
            this.onChangeMonth(event);
        }
    }

    onSelectDate(event: CustomEvent) {
        const { date, viewDate } = event.detail;
        let timestamp = (viewDate as Date).getTime();

        switch (date.length) {
            case 1:
                if (timestamp === this._rangeEnd) {
                    timestamp = this._rangeStart;

                    this.clearRanges();
                    this._datepicker.setDate(timestamp, { clear: true });
                } else if (timestamp === this._rangeStart) {
                    timestamp = this._rangeEnd;

                    this.clearRanges();
                    this._datepicker.setDate(timestamp, { clear: true });
                } else {
                    this._rangeStart = timestamp;
                    this._enableHoverRange = true;

                    emit(this, "atlas-calendar-date-change", { detail: this.getDates() });
                }
                break;
            case 2:
                if (timestamp < this._rangeStart) {
                    this._rangeEnd = this._rangeStart;
                    this._rangeStart = timestamp;
                } else {
                    this._rangeEnd = timestamp;
                }

                this._enableHoverRange = false;
                this._rangeHover = null;

                emit(this, "atlas-calendar-date-change", { detail: this.getDates() });
                break;
            case 3:
                this.clearRanges();
                this._datepicker.setDate(timestamp, { clear: true });
                break;
            default:
                timestamp = this._rangeStart;

                this.clearRanges();
                this._datepicker.setDate(timestamp, { clear: true });
        }

        this.setDateLimit();
        this.setRangesOnCalendar();
    }

    setDateLimit() {
        let minDate = null;
        let maxDate = null;

        if (this._rangeStart && !this._rangeEnd) {
            minDate = new Date(this._rangeStart);
            maxDate = new Date(this._rangeStart);
            minDate.setDate(minDate.getDate() - 31);
            maxDate.setDate(maxDate.getDate() + 31);
        }

        this._datepicker.setOptions({
            minDate,
            maxDate
        });
    }

    getAllDaysInRange() {
        let startDate = this._rangeStart;
        let endDate = this._rangeEnd || this._rangeHover || this._rangeStart;

        if (startDate > endDate) {
            [startDate, endDate] = [endDate, startDate];
        }

        const daysInRange = [];
        const dayInMilli = 60 * 60 * 24 * 1000;

        for (let i = startDate; i <= endDate; i += dayInMilli) {
            daysInRange.push(i);
        }

        return daysInRange;
    }

    setRangesOnCalendar() {
        const daysInRange = this.getAllDaysInRange();

        this._calendarContainer.querySelectorAll(".datepicker-grid span").forEach((cell: HTMLElement) => {
            cell.classList.remove("range-start");
            cell.classList.remove("range-end");
            cell.classList.remove("in-range");
            cell.classList.remove("in-range-no-after");
            cell.classList.remove("in-range-no-before");
        });

        if (daysInRange.length > 1) {
            daysInRange.forEach((day, index) => {
                const cell = this._calendarContainer.querySelector(`.datepicker-grid span[data-date='${day}']`);

                if (!cell) return;

                if (!this._rangeEnd) {
                    cell.classList.add("in-range");

                    if (index === daysInRange.length - 1) {
                        cell.classList.add("in-range-no-after");
                    } else if (index === 0) {
                        cell.classList.add("in-range-no-before");
                    }
                } else if (index === 0) {
                    cell.classList.add("range-start");
                } else if (index === daysInRange.length - 1) {
                    cell.classList.add("range-end");
                } else {
                    cell.classList.add("in-range");
                }
            });
        }
    }

    @Watch("flaggedDates", true)
    @Watch("flags", true)
    setFlaggedDatesOnCalendar() {
        this._calendarContainer.querySelectorAll(".datepicker-grid span").forEach((cell: HTMLElement) => {
            cell.querySelector(".badge-container")?.remove();
        });

        this.flags?.forEach((flag: CalendarFlag) => {
            this.flaggedDates
                ?.filter((flaggedDate: CalendarFlaggedDate) => flag.name === flaggedDate.flag)
                ?.forEach((flaggedDate: CalendarFlaggedDate) => this.createFlagOnCalendar(flag, flaggedDate));
        });
    }

    createFlagOnCalendar(flag: CalendarFlag, flaggedDate: CalendarFlaggedDate) {
        const date = Datepicker.getDateAsTimestamp(flaggedDate.date);
        const cell = this._calendarContainer.querySelector(`.datepicker-grid span[data-date='${date}']`);

        if (!cell) return;

        let badgeContainer = cell.querySelector(".badge-container");

        if (!badgeContainer) {
            badgeContainer = document.createElement("div");
            badgeContainer.classList.add("badge-container");
            cell.appendChild(badgeContainer);
        }

        const tooltipId = `cell-${date}-${flag.name}-tooltip`;

        const badge = document.createElement("div");
        badge.classList.add("badge");
        badge.classList.add(`bg-${flag.theme}`);
        badge.classList.toggle(`bg-${flag.theme}-${flag["theme-variation"]}`, !!flag["theme-variation"]);
        badge.classList.toggle("circle", flag.type === "circle");
        badge.setAttribute("data-atlas-tooltip", tooltipId);

        badgeContainer.appendChild(badge);

        const tooltip = document.createElement("atlas-tooltip");
        tooltip.id = tooltipId;
        tooltip.placement = "bottom";
        tooltip.trigger = "hover";

        tooltip.appendChild(document.createTextNode(flaggedDate.value));

        badgeContainer.appendChild(tooltip);
    }

    renderFlagItems() {
        return this.flags.map((flag: CalendarFlag) => {
            const flagClass = {
                "flag-item": true
            };

            const badgeClass = {
                "flag-badge": true,
                "circle": flag.type === "circle",
                [`bg-${flag.theme}`]: true,
                [`bg-${flag.theme}-${flag["theme-variation"]}`]: !!flag["theme-variation"]
            };

            return html`
                <div class=${classMap(flagClass)}>
                    <div class=${classMap(badgeClass)}></div>
                    ${flag.label}
                </div>
            `;
        });
    }

    renderElement() {
        const hasFlags = !!this.flags && this.flags.length > 0;

        return html`
            <div class="calendar-wrapper">
                ${when(hasFlags, () => html`<div class="calendar-flags">${this.renderFlagItems()}</div>`)}
                <div class="atlas-calendar"></div>
            </div>
        `;
    }

    renderSkeleton() {
        return html`
            <div class="calendar-wrapper">
                <div class="calendar-flags">
                    <div class="flag-item">
                        <div class="skeleton" style="width: 10px; height: 10px"></div>
                        <div class="skeleton" style="width: 100px; height: 16px"></div>
                    </div>
                </div>
                <div class="atlas-calendar"></div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-calendar": AtlasCalendar;
    }
}
