import { literal, html } from "lit/static-html.js";
import { customElement, property } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";

import { AtlasButtonBase, ButtonBaseProps } from "./atlas-button-base";
import AtlasForm from "@/components/form/atlas-form/atlas-form";

export type ButtonProps = ButtonBaseProps & {
    submit: boolean;
    formaction: string;
};

/**
 * @prop {boolean} submit - Booleano que indica se o botão é do tipo submit
 * @prop {string} formaction - URL que será alvo do submit do formulário, caso o botão esteja dentro de um formulário e seja do tipo submit
 *
 * @tag atlas-button
 */
@customElement("atlas-button")
export class AtlasButton extends AtlasButtonBase {
    @property({ type: Boolean }) submit: boolean;

    @property({ type: String }) formaction: string;

    handleButtonClick(event: PointerEvent) {
        const closestForm: AtlasForm = this.closest("atlas-form");

        if (this.submit && closestForm) {
            closestForm.submit(this.formaction);
        } else {
            super.handleButtonClick(event);
        }
    }

    renderElement() {
        const containerClass = {
            "btn-tooltip-wrapper": true,
            "btn-block": this.block
        };

        const buttonClass = {
            ...this.getButtonClasses(),
            "rounded-pill": this.pill
        };

        const buttonType = this.submit ? "submit" : "button";
        const tag = this.href ? literal`a` : literal`button`;

        /* eslint-disable lit/binding-positions, lit/no-invalid-html */
        return html`
            <div
                tabindex=${this.isDisabled() ? "0" : "-1"}
                class="${classMap(containerClass)}"
                data-atlas-tooltip="button-tooltip"
            >
                <${tag}
                    class=${classMap(buttonClass)}
                    type=${buttonType}
                    ?disabled=${this.isDisabled()}
                    @click=${this.handleButtonClick}
                    href=${ifDefined(this.href ? this.href : undefined)}
                    rel="${ifDefined(this.href && this.isExternalLink ? "noreferrer" : undefined)}"
                    target="${ifDefined(this.href && this.isExternalLink ? "_blank" : undefined)}"
                    tabindex="${ifDefined(this.disabled ? "-1" : "0")}"
                >
                    ${this.getButtonIcon(this.getCurrentIcon())} ${this.getButtonText()} ${this.renderBadge()}
                </${tag}>
            </div>
            ${this.renderTooltip("button-tooltip")}
        `;
        /* eslint-enable lit/binding-positions, lit/no-invalid-html */
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-button": AtlasButton;
    }
}
