import FormElement from "@/components/form/form-element";
import FieldValidator from "./field-validator";

import Datepicker from "@/vendors/datepicker-utils";

export default class DateValidator implements FieldValidator {
    name = "date";

    errorType: "none" | "min" | "max" | "both" | "format" = "none";

    minDate: Date;

    maxDate: Date;

    getInvalidMessage(): string {
        const messageMap = {
            min: () => `A data informada não deve ser menor que ${this.formatDate(this.minDate)}`,
            max: () => `A data informada não deve ser maior que ${this.formatDate(this.maxDate)}`,
            both: () => `Informe uma data entre ${this.formatDate(this.minDate)} e ${this.formatDate(this.maxDate)}`,
            format: () => "Formato de data inválido, utilize o formato DD/MM/AAAA"
        };

        return this.errorType === "none" ? "" : messageMap[this.errorType]();
    }

    validate(element: FormElement): boolean {
        const value = element.getElementValue();

        if (!value) {
            return true;
        }

        if (value.length < 10) {
            this.errorType = "format";
            return false;
        }

        this.updateMinAndMax(element);
        this.errorType = "none";

        const valueAsTime = Datepicker.parseDate(value, Datepicker.dateFormat);

        if (this.minDate && valueAsTime < this.minDate.getTime()) {
            this.errorType = this.maxDate ? "both" : "min";
        } else if (this.maxDate && valueAsTime > this.maxDate.getTime()) {
            this.errorType = this.minDate ? "both" : "max";
        }

        return this.errorType === "none";
    }

    updateMinAndMax(element: HTMLElement) {
        const preventPastDate = element.hasAttribute("prevent-past-date");
        const minDate = element.getAttribute("min-date");

        this.minDate = Datepicker.getDatepickerMinDate(preventPastDate, minDate);

        if (this.minDate) {
            this.minDate.setHours(0, 0, 0, 0);
        }

        const preventLaterDate = element.hasAttribute("prevent-later-date");
        const maxDate = element.getAttribute("max-date");

        this.maxDate = Datepicker.getDatepickerMaxDate(preventLaterDate, maxDate);

        if (this.maxDate) {
            this.maxDate.setHours(23, 59, 59, 999);
        }
    }

    formatDate(date: Date) {
        return Datepicker.formatDate(date, Datepicker.dateFormat);
    }
}
