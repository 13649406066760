import { html } from "lit";
import { customElement, query } from "lit/decorators.js";

import { classMap } from "lit/directives/class-map.js";

import OverlayElement from "@/components/display/overlay-element";
import styles from "./atlas-tooltip.scss";

/**
 * Tooltip é um box flutuante que abre quando passa-se o mouse sobre um elemento da interface e que contém uma explicação adicional sobre tal elemento.
 *
 * @prop {boolean} open - Indica se o tooltip está aberto
 * @prop {boolean} disabled - Indica se o tooltip está desabilitado
 * @prop {OverlayPlacement} placement - A posição do tooltip em relação ao elemento que o acionou
 * @prop {OverlayTrigger} trigger - O gatilho que irá acionar o tooltip
 *
 * @slot - O slot padrão serve para adicionar conteúdos ao tooltip
 *
 * @event {CustomEvent} atlas-tooltip-opened - Evento disparado quando o tooltip é aberto
 * @event {CustomEvent} atlas-tooltip-closed - Evento disparado quando o tooltip é fechado
 *
 * @tag atlas-tooltip
 */
@customElement("atlas-tooltip")
export default class AtlasTooltip extends OverlayElement {
    static styles = styles;

    @query(".atlas-tooltip")
    private tooltip?: HTMLElement;

    constructor() {
        super("tooltip", "absolute", "hover focus");
    }

    /**
     * @override
     */
    getOverlayElement(): HTMLElement {
        return this.tooltip;
    }

    /**
     * @override
     */
    getPopperModifiers(): Array<object> {
        const modifiers = super.getPopperModifiers();

        return [...modifiers, { name: "arrow", options: { element: ".tooltip-arrow" } }];
    }

    /**
     * @override
     */
    onOpenOverlay() {
        this.tooltip.hidden = false;
    }

    /**
     * @override
     */
    onCloseOverlay() {
        setTimeout(() => {
            this.tooltip.hidden = true;
        }, 250);
    }

    render() {
        const tooltipClass = {
            "atlas-tooltip": true,
            "tooltip": true,
            "bs-tooltip-auto": true,
            "fade": true,
            "show": this.open
        };

        return html`
            <div
                class=${classMap(tooltipClass)}
                role="tooltip"
                aria-hidden=${this.open ? "false" : "true"}
                aria-labelledby="tooltip-slot"
            >
                <div class="tooltip-arrow"></div>
                <div class="tooltip-inner">
                    <slot id="tooltip-slot"></slot>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "atlas-tooltip": AtlasTooltip;
    }
}
