import AtlasDropzone from "@/components/form/atlas-dropzone/atlas-dropzone";
import FieldValidator from "./field-validator";

export default class DropzoneValidator implements FieldValidator {
    name = "dropzone";

    getInvalidMessage(): string {
        return "Aguarde a conclusão do carregamento";
    }

    validate(element: AtlasDropzone): boolean {
        if (element.preview) {
            return true;
        }

        return !element.isUploadingFiles();
    }
}
